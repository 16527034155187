import * as d3 from '../../d3-bundle';
import { IMargin } from '../margin';
import { ILegendSettings } from '../legend-settings';
import { ISize } from '../size';
import { SVGSelection } from '../../untyped-selection';
import { SharedState } from '../shared-state';
import { FeatureChannels } from '../data-pipeline/types/feature-channels';
import { SourceSplitInspector } from '../channel-data-inspectors/source-split-inspector';
import { SourceLoaderViewModel } from '../channel-data-loaders/source-loader-set';
import { GetChannelColorDelegate } from '../chart-settings';

interface IChartSettings {
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly legend: ILegendSettings;
  readonly getChannelColor: GetChannelColorDelegate;
}

export interface ISourceLabelsSource {
  readonly name: string;
  isVisible: boolean;
  featureChannels: FeatureChannels;
}

export const SOURCE_LABELS_CONTAINER_CLASS = 'source-labels';

export class SourceLabelsRenderer {
  private inner: SourceLabelsRendererInner;

  constructor(chartSettings: IChartSettings) {
    this.inner = new SourceLabelsRendererInner(chartSettings, new SourceSplitInspector());
  }

  public sharedState(value: SharedState): this {
    this.inner.sharedState = value;
    return this;
  }

  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  public sources(value: ReadonlyArray<ISourceLabelsSource>): this {
    this.inner.sources = value;
    return this;
  }

  // This is here for compatibility with MultiPlotViewer, but eventually
  // should be removed and then ISourceLabelsSource.name can be readonly.
  public getSourceVisibility(): boolean[] {
    return this.inner.sources.map(v => v.isVisible);
  }

  public renderLegendLines(value: boolean): this {
    this.inner.renderLegendLines = value;
    return this;
  }

  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.inner.listeners.on(typenames, callback);
    return this;
  }

  public static getRequiredVerticalSpace(sourceCount: number): number {
    // TODO: Extract getRequiredVerticalSpace so we don't need this hack.
    return new SourceLabelsRendererInner(undefined as any, undefined).getRequiredVerticalSpace(sourceCount);
  }
}

export class SourceLabelsRendererInner {

  private gapToChart: number = 10;
  private labelTargetYPosition: number = 8 - this.gapToChart;
  private lineColor: string = 'gray';

  public listeners = d3.dispatch('changed');
  public sources: ReadonlyArray<ISourceLabelsSource> = [];
  public sharedState?: SharedState;
  public renderLegendLines: boolean = true;

  constructor(
    public readonly settings: IChartSettings,
    private readonly sourceSplitInspector: SourceSplitInspector | undefined) {
  }

  public render(selection: SVGSelection) {
    if (this.sources.length < 1 || !this.sourceSplitInspector) {
      return;
    }

    const sourceSplitInspector = this.sourceSplitInspector;

    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + SOURCE_LABELS_CONTAINER_CLASS).data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', SOURCE_LABELS_CONTAINER_CLASS);
    let container = containerEnter.merge(containerUpdate);

    let gUpdate = container.selectAll<SVGGElement, ISourceLabelsSource>('.source-legend').data(() => this.sources as ISourceLabelsSource[]);
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g').attr('class', 'source-legend');
    let g = gEnter.merge(gUpdate);

    g.attr('transform', (d, i) =>
      'translate('
      + (this.settings.svgPadding.left + this.settings.chartMargin.left + this.settings.chartSize.width + this.settings.legend.renderChannelWidth)
      + ','
      + (this.settings.svgPadding.top + this.settings.chartMargin.top)
      + ')');

    if (this.renderLegendLines) {
      gEnter.append('line')
        .attr('class', 'vertical-line')
        .attr('stroke-width', 1)
        .attr('stroke', this.lineColor);

      g.select('.vertical-line')
        .attr('x1', (d, i) => this.getLabelTargetXPosition(i))
        .attr('y1', this.labelTargetYPosition)
        .attr('x2', (d, i) => this.getLabelTargetXPosition(i))
        .attr('y2', (d, i) => this.getLabelLineYPosition(i));

      gEnter.append('line')
        .attr('class', 'horizontal-line')
        .attr('stroke-width', 1)
        .attr('stroke', this.lineColor);

      g.select('.horizontal-line')
        .attr('x1', 8)
        .attr('y1', (d, i) => this.getLabelLineYPosition(i))
        .attr('x2', (d, i) => this.getLabelTargetXPosition(i))
        .attr('y2', (d, i) => this.getLabelLineYPosition(i));
    }

    gEnter.append('rect')
      .attr('class', 'legend-square')
      .attr('width', this.settings.legend.blobSize)
      .attr('height', this.settings.legend.blobSize)
      .style('stroke', this.lineColor)
      .style('cursor', 'pointer')
      .on('click', (_, d) => {
        d.isVisible = !d.isVisible;
        this.listeners.call('changed');
        if (this.sharedState) {
          this.sharedState.sourceLoaderSet.raiseChangedEvent();
        }
      });
    g.select('.legend-square')
      .attr('y', (d, i) => this.getLabelYPosition(i) - this.settings.legend.blobSize + 1.5)
      .attr('x', -this.settings.legend.blobSize / 2)
      .style('fill', d => d.isVisible ? this.lineColor : 'transparent');

    let blobSize = this.settings.legend.blobSize;
    let arrowDownPath = `0,1.5 ${blobSize / 2},${blobSize * 0.75} ${blobSize},1.5`;
    let arrowUpPath = `0,${blobSize - 1.5} ${blobSize / 2},${blobSize * 0.25} ${blobSize},${blobSize - 1.5}`;

    if (this.sharedState) {
      const sharedState = this.sharedState;

      gEnter.append('g')
        .attr('class', 'legend-down')
        .append('polyline')
        .attr('points', arrowDownPath)
        .attr('fill', this.lineColor)
        .style('stroke', this.lineColor);

      let polylinesDown = g.select('.legend-down');
      polylinesDown.on('click', e => {
          const n = polylinesDown.nodes();
          const i = n.indexOf(e.currentTarget);
          sharedState.sourceLoaderSet.move(i, i + 1);
        });
      g.select('.legend-down')
        .attr('transform', (d, i) =>
          'translate('
          + (-2 * this.settings.legend.blobSize)
          + ','
          + (this.getLabelYPosition(i) - this.settings.legend.blobSize + 1.5)
          + ')')
        .select('polyline')
        .style('opacity', (d, i) => i === this.sources.length - 1 ? 0.2 : 1)
        .style('cursor', (d, i) => i === this.sources.length - 1 ? 'inherit' : 'pointer');

      gEnter.append('g')
        .attr('class', 'legend-up')
        .append('polyline')
        .attr('points', arrowUpPath)
        .attr('fill', this.lineColor)
        .style('stroke', this.lineColor);

      let polylinesUp = g.select('.legend-up');
      polylinesUp.on('click', e => {
          const n = polylinesUp.nodes();
          const i = n.indexOf(e.currentTarget);
          sharedState.sourceLoaderSet.move(i, i - 1);
        });
      g.select('.legend-up')
        .attr('transform', (d, i) =>
          'translate('
          + (-3 * this.settings.legend.blobSize)
          + ','
          + (this.getLabelYPosition(i) - this.settings.legend.blobSize + 1.5)
          + ')')
        .select('polyline')
        .style('opacity', (d, i) => i === 0 ? 0.2 : 1)
        .style('cursor', (d, i) => i === 0 ? 'inherit' : 'pointer');

      gEnter.append('g')
        .attr('class', 'split-source')
        .attr('title', 'Split')
        .style('fill', this.lineColor)
        .append('text')
        .text('\uf0e8');

      let splits = g.select('.split-source');
      splits.on('click', (e, d) => {
          const n = splits.nodes();
          const i = n.indexOf(e.currentTarget);
          let source = sharedState.sourceLoaderSet.sources[i];
          if (sourceSplitInspector.hasSplitIndices(d.featureChannels.splitChannel)) {
            let newSources = sourceSplitInspector.splitSource(source, d.featureChannels.splitChannel);
            sharedState.sourceLoaderSet.replace(i, newSources.map(v => new SourceLoaderViewModel(v, d.isVisible)));
          } else if (sourceSplitInspector.canMergeSource(source)) {
            let mergeInformation = sourceSplitInspector.getMergeSourceInformation(
              source,
              sharedState.sourceLoaderSet.sources);

            if (mergeInformation) {
              sharedState.sourceLoaderSet.replaceAll(
                mergeInformation.sourceIndices,
                new SourceLoaderViewModel(mergeInformation.originSource, true));
            }
          }
        });

      g.select('.split-source')
        .attr('transform', (d, i) =>
          'translate('
          + (-4.25 * this.settings.legend.blobSize)
          + ','
          + (this.getLabelYPosition(i))
          + ')')
        .style('display', (d: ISourceLabelsSource, i) => {
          if (sourceSplitInspector.hasSplitIndices(d.featureChannels.splitChannel)) {
            return 'inherit';
          } else if (sourceSplitInspector.canMergeSource(sharedState.sourceLoaderSet.sources[i])) {
            return 'inherit';
          }
          return 'none';
        })
        .style('cursor', 'pointer')
        .select('text')
        .attr('transform', (d, i) => {
          if (sourceSplitInspector.canMergeSource(sharedState.sourceLoaderSet.sources[i])) {
            let offset = this.settings.legend.blobSize;
            return `rotate(180) translate(-${offset}, ${offset / 1.75})`;
          }
          return null;
        });
    }

    gEnter.append('text')
      .attr('class', 'source-label-text')
      .attr('text-anchor', 'end');
    g.select('.source-label-text')
      .attr('y', (d, i) => this.getLabelYPosition(i))
      .attr('x', (d, i) => {
        let baseOffset = -1;
        let upDownArrowsOffset = this.sharedState ? -2.5 : 0;
        let splitOffset = this.sharedState &&
          (sourceSplitInspector.hasSplitIndices(d.featureChannels.splitChannel)
            || sourceSplitInspector.canMergeSource(this.sharedState.sourceLoaderSet.sources[i]))
          ? -1 : 0;
        return (baseOffset + upDownArrowsOffset + splitOffset) * this.settings.legend.blobSize;
      })
      .attr('fill', (d, i) =>
        this.sources.length <= 1 ? 'black' : this.settings.getChannelColor(0, i))
      .text(d => d.name);
  }

  private getLabelTargetXPosition(index: number): number {
    return this.settings.legend.valueWidth * (index + 1) - 2.5;
  }

  private getLabelYPosition(index: number): number {
    return this.getLabelYPositionForSources(index, this.sources.length);
  }

  private getLabelYPositionForSources(index: number, sourceCount: number): number {
    let position = sourceCount - 1 - index;
    return -(15 * position) - this.gapToChart;
  }

  private getLabelLineYPosition(index: number): number {
    return this.getLabelYPosition(index) - 3 - 0.5;
  }

  public getRequiredVerticalSpace(sourceCount: number): number {
    if (sourceCount < 1) {
      return 0;
    }

    return 5 - this.getLabelYPositionForSources(-1, sourceCount) - this.gapToChart;
  }
}
