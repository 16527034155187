import { AmbientLight, DirectionalLight, Scene } from 'three';

export class SimpleLightingRenderer {
  constructor(
    private readonly scene: Scene) {
  }

  public build() {

    let ambientLight = new AmbientLight(0xd0d0d0, Math.PI);
    this.scene.add(ambientLight);

    let directionalLight = new DirectionalLight(0x777777, Math.PI);
    directionalLight.position.set(100, 100, -100).normalize();
    this.scene.add(directionalLight);

    let directionalLight2 = new DirectionalLight(0x555555, Math.PI);
    directionalLight2.position.set(-100, 100, 100).normalize();
    this.scene.add(directionalLight2);

    //let helper = new DirectionalLightHelper(directionalLight, 5);
    //this.scene.add(helper);
  }

  public animate() {

  }
}
