var overshoot = 1.70158;
export var backIn = function custom(s) {
  s = +s;
  function backIn(t) {
    return t * t * ((s + 1) * t - s);
  }
  backIn.overshoot = custom;
  return backIn;
}(overshoot);
export var backOut = function custom(s) {
  s = +s;
  function backOut(t) {
    return --t * t * ((s + 1) * t + s) + 1;
  }
  backOut.overshoot = custom;
  return backOut;
}(overshoot);
export var backInOut = function custom(s) {
  s = +s;
  function backInOut(t) {
    return ((t *= 2) < 1 ? t * t * ((s + 1) * t - s) : (t -= 2) * t * ((s + 1) * t + s) + 2) / 2;
  }
  backInOut.overshoot = custom;
  return backInOut;
}(overshoot);