export interface IMargin {
  readonly top: number;
  readonly right: number;
  readonly bottom: number;
  readonly left: number;
}

export class Margin implements IMargin {
  constructor(
    private _top: number,
    private _right: number,
    private _bottom: number,
    private _left: number) {
  }

  public get top(): number {
    return this._top;
  }

  public get right(): number {
    return this._right;
  }

  public get bottom(): number {
    return this._bottom;
  }

  public get left(): number {
    return this._left;
  }
}
