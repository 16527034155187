import { Utilities } from '../../utilities';
import * as d3 from '../../d3-bundle';
import {
  Scene,
  WebGLRenderer,
  Vector2,
  Raycaster,
  ColorManagement,
  LinearSRGBColorSpace
} from 'three';
import { ISize } from '../size';
import { Subscription, Subject } from 'rxjs';
import { SharedState } from '../shared-state';
import { HTMLDivSelection, SVGSelection } from '../../untyped-selection';
import { Position } from '../position';
import { SourceLabelsRenderer } from '../components/source-labels-renderer';
import { IMargin, Margin } from '../margin';
import { FeatureChannels } from '../data-pipeline/types/feature-channels';
import { SourceData } from '../data-pipeline/types/source-data';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { ILegendSettings } from '../legend-settings';
import { SiteHooks } from '../../site-hooks';
import { GetChannelColorDelegate } from '../chart-settings';
import { RendererPool } from './renderer-pool';
import { CameraState } from './camera-state';
import { ViewerChannelDataMap } from '../channel-data-loaders/viewer-channel-data-map';

const frustumSize = 10;
const cameraFov: number = 60;

interface Settings3d {
  svgSize: ISize;
  svgPadding: IMargin;
  chartMargin: IMargin;
  chartSize: ISize;
  readonly legend: ILegendSettings;
  sourceCount: number;
  readonly getChannelColor: GetChannelColorDelegate;
}

export abstract class Visualization3dBase {
  public errorEvent: Subject<string> = new Subject<string>();

  protected cameraNear: number = 0.1;
  protected cameraFar: number = 10000;

  protected isDisposed: boolean = false;
  protected isProcessingData: boolean = false;

  protected elementId?: string;
  protected element?: HTMLElement;

  protected svgParent?: HTMLDivSelection;
  protected svg?: SVGSelection;
  protected sourceLabelsRenderer?: SourceLabelsRenderer;

  protected sceneParent?: HTMLDivSelection;
  protected scene?: Scene;
  protected renderer: WebGLRenderer | undefined;

  protected cameraState?: CameraState;

  protected lastMousePosition?: Vector2;
  protected lastProcessedMousePosition?: Vector2;
  private mouseRaycaster = new Raycaster();

  protected sourceData?: ReadonlyArray<SourceData>;

  private baseSettings?: Settings3d;

  protected readonly subscriptions: Subscription = new Subscription();

  private lastFrameNumber: number = 0;
  private start: number = Date.now();

  protected get isRenderingManually(): boolean {
    return this.frameRate <= 0;
  }

  constructor(
    protected readonly frameRate: number,
    protected readonly siteHooks: SiteHooks,
    protected readonly sharedState: SharedState,
    private readonly renderSourceLegendLines: boolean = false) {
  }

  protected abstract processLayout(): void;
  protected abstract performAnimate3d(mouseRaycaster: Raycaster | undefined): void;
  protected abstract performBuild2d(): void;
  protected abstract performRender2d(): void;
  protected abstract performSetData2d(): void;
  protected abstract performSourcesChanged(): Promise<void>;

  public get definedBaseSettings(): Settings3d {
    if (!this.baseSettings) {
      throw new Error('Base settings not found.');
    }

    return this.baseSettings;
  }

  protected async build(elementId: string, settings: Settings3d, layout?: IMultiPlotLayout): Promise<boolean> {
    this.isProcessingData = true;
    try {
      this.elementId = elementId;
      this.baseSettings = settings;

      const element = document.getElementById(this.elementId);
      if (!element) {
        return false; // The page has been unloaded.
      }

      this.element = element;

      this.baseSettings.svgSize = Utilities.getRequiredSvgSize(this.elementId, this.baseSettings.svgSize);

      await this.loadFromLayout(layout);

      if (!this.exists) {
        return false;
      }

      this.processLayout();

      await this.build2d();

      if (!this.exists) {
        return false;
      }

      this.build3d();

      return true;
    } finally {
      this.isProcessingData = false;
    }
  }

  private build3d() {
    const settings = this.definedBaseSettings;

    this.sceneParent = this.select()
      .append<HTMLDivElement>('div')
      .attr('class', 'viewer-3d-container');

    this.scene = new Scene();
    this.scene.background = null;

    this.renderer = RendererPool.get();
    this.renderer.setSize(settings.svgSize.width, settings.svgSize.height);
    ColorManagement.enabled= false;
    this.renderer.outputColorSpace = LinearSRGBColorSpace;
    (this.sceneParent.node() as HTMLElement).appendChild(this.renderer.domElement);

    let aspect = settings.svgSize.width / settings.svgSize.height;
    this.cameraState = new CameraState(frustumSize * aspect, frustumSize, cameraFov, this.cameraNear, this.cameraFar, this.cameraNear, this.cameraFar);
    //this.camera = new OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, 0.1, 10000 );
    //this.camera = new PerspectiveCamera( this.cameraFov, aspect, 0.1, 10000 );

    this.sceneParent.on('mousemove', currentEvent => {
      let mouseEvent = <MouseEvent>currentEvent;
      let position = new Position(mouseEvent.offsetX, mouseEvent.offsetY);

      // calculate mouse position in normalized device coordinates
      // (-1 to +1) for both components.
      // https://threejs.org/docs/#api/core/Raycaster
      this.lastMousePosition = new Vector2(
        (position.x / settings.svgSize.width) * 2 - 1,
        -1 * ((position.y / settings.svgSize.height) * 2 - 1));

      if (this.isRenderingManually) {
        this.render3d();
      }
    });
  }

  protected beginRender() {
    this.subscriptions.add(this.sharedState.windowResizeNews.subscribe(() => this.performResize()));
    this.subscriptions.add(this.sharedState.sourceLoaderSet.changed.subscribe(() => this.handleSourcesChanged()));

    if (this.isRenderingManually) {
      this.subscriptions.add(this.sharedState.windowResizeNews.subscribe(() => this.render3d()));
      this.subscriptions.add(this.sharedState.sourceLoaderSet.changed.subscribe(() => this.render3d()));
      this.subscriptions.add(this.sharedState.keyPressNews.subscribe(() => this.render3d()));
    }

    this.renderAll(true);
    this.beginAnimate3d();
  }

  private beginAnimate3d() {
    this.render3d();
  }

  protected render3d() {
    requestAnimationFrame(() => this.render3dInner());
  }

  private render3dInner() {
    if (this.isDisposed) {
      return;
    }

    if (!this.cameraState || !this.scene) {
      return;
    }

    if (this.frameRate > 0) {
      let elapsed = Date.now() - this.start;
      requestAnimationFrame(() => this.render3dInner());

      let frameNumber = Math.round(elapsed / (1000 / this.frameRate));
      if (frameNumber === this.lastFrameNumber) {
        return;
      }

      this.lastFrameNumber = frameNumber;
    }

    let mouseRaycaster: Raycaster | undefined;
    if (this.lastMousePosition && this.lastMousePosition !== this.lastProcessedMousePosition) {
      this.lastProcessedMousePosition = this.lastMousePosition;
      mouseRaycaster = this.mouseRaycaster;
      mouseRaycaster.params.Line.threshold = 0.01;
      mouseRaycaster.setFromCamera(this.lastMousePosition, this.cameraState.camera);
    }

    this.performAnimate3d(mouseRaycaster);

    if (this.renderer) {
      this.renderer.render(this.scene, this.cameraState.camera);
    }
  }

  protected async build2d(): Promise<void> {
    const settings = this.definedBaseSettings;
    this.updateChartMargin();

    this.svgParent = this.select()
      .append<HTMLDivElement>('div')
      .attr('class', 'canvas-viewer viewer-3d-controls-container');

    this.svg = this.svgParent
      .append<SVGElement>('svg')
      .attr('width', settings.svgSize.width)
      .attr('height', settings.svgSize.height)
      .attr('class', 'viewer-3d-controls')
      .attr('id', 'svg');

    // Create Source Labels
    this.sourceLabelsRenderer = new SourceLabelsRenderer(settings);
    this.sourceLabelsRenderer
      .sharedState(this.sharedState)
      .renderLegendLines(this.renderSourceLegendLines);

    this.performBuild2d();
  }

  protected updateChartMargin() {
    const settings = this.definedBaseSettings;
    let sourceCount = settings.sourceCount;

    const margin = 8;
    settings.chartMargin = new Margin(
      SourceLabelsRenderer.getRequiredVerticalSpace(sourceCount) || 20,
      margin,
      margin,
      margin);
  }

  protected async loadFromLayout(inputLayout?: IMultiPlotLayout): Promise<void> {
    // NOTE: This is a dummy implementation for when there is no layout.
    // Override in derived classes if required.

    let sourceData: SourceData[] = [];
    for (let source of this.sharedState.sourceLoaderSet.sources) {
      let metadata = await source.getSourceMetadata();
      sourceData.push(new SourceData(metadata.name, new ViewerChannelDataMap({}), new FeatureChannels(undefined, undefined), source));
    }

    this.sourceData = sourceData;
  }

  protected async handleSourcesChanged() {
    if (!this.sourceData) {
      return;
    }

    this.isProcessingData = true;
    try {
      await this.loadFromLayout();

      await this.performSourcesChanged();

      const settings = this.definedBaseSettings;
      settings.sourceCount = this.sourceData.length;

      this.renderAll(true);
    } catch (error) {
      this.setError(this.siteHooks.getFriendlyErrorAndLog(error));
    } finally {
      this.isProcessingData = false;
    }
  }

  protected setAllData() {
    if (!this.sourceLabelsRenderer || !this.sourceData) {
      return;
    }

    this.sourceLabelsRenderer.sources(this.sourceData);

    this.performSetData2d();
  }

  protected renderAll(setAllData: boolean) {
    if (!this.exists || !this.sourceLabelsRenderer || !this.svg) {
      return;
    }

    if (setAllData) {
      this.processLayout();
      this.setAllData();
    }

    this.updateChartMargin();

    this.performRender2d();

    this.sourceLabelsRenderer.render(this.svg);
  }

  protected setError(message: string) {
    this.errorEvent.next(message);
  }

  public dispose() {
    this.isDisposed = true;
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.renderer) {
      RendererPool.release(this.renderer);
      this.renderer = undefined;
    }
  }

  protected performResize() {
    try {
      if (!this.elementId || !this.svg || !this.cameraState) {
        return;
      }

      const settings = this.definedBaseSettings;
      settings.svgSize = Utilities.getRequiredSvgSize(this.elementId, settings.svgSize);
      this.svg.attr('width', settings.svgSize.width);
      this.svg.attr('height', settings.svgSize.height);

      let aspect = settings.svgSize.width / settings.svgSize.height;

      this.cameraState.setAspect(aspect);

      this.definedRenderer.setSize(settings.svgSize.width, settings.svgSize.height);

      // If the layout is in the middle of being updated, we may not be able to render.
      if (!this.isProcessingData) {
        this.renderAll(true);
      }
    } catch (error) {
      this.setError(this.siteHooks.getFriendlyErrorAndLog(error));
    }
  }

  protected select(selection: string = '') {
    return d3.select('#' + this.elementId + ' ' + selection);
  }

  protected selectAll(selection: string = '') {
    return d3.selectAll('#' + this.elementId + ' ' + selection);
  }

  protected get exists(): boolean {
    return !!(this.elementId && document.getElementById(this.elementId));
  }

  protected get definedRenderer(): WebGLRenderer {
    if (!this.renderer) {
      throw new Error('Renderer is not defined.');
    }

    return this.renderer;
  }
}
