import { IRectangle } from '../rectangle';

export class ZoomBrushSelection implements IRectangle {
  public readonly isSelectionReversedX: boolean = false;
  public readonly isSelectionReversedY: boolean = false;

  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly width: number,
    public readonly height: number) {

    if (width < 0) {
      this.x = x + width;
      this.width = -1 * width;
      this.isSelectionReversedX = true;
    }

    if (height < 0) {
      this.y = y + height;
      this.height = -1 * height;
      this.isSelectionReversedY = true;
    }
  }
}
