import { Vector3 } from 'three';
import { CoordinateTriplet } from './coordinate-triplet';

export class CarCoordinate {
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly z: number) {
  }

  public get worldVector(): Vector3 {
    return new Vector3(this.x, -this.z, this.y);
  }

  public withOffset(offset: CoordinateTriplet): CarCoordinate {
    return new CarCoordinate(this.x + offset[0], this.y + offset[1], this.z + offset[2]);
  }

  public equals(other: CarCoordinate): boolean {
    if (!other) {
      return false;
    }

    return this.x === other.x && this.y === other.y && this.z === other.z;
  }

  public isCloseTo(other: CarCoordinate): boolean {
    if (!other) {
      return false;
    }

    return this.isDimensionCloseTo(this.x, other.x)
      && this.isDimensionCloseTo(this.y, other.y)
      && this.isDimensionCloseTo(this.z, other.z);
  }

  private isDimensionCloseTo(a: number, b: number) {
    return Math.abs(a - b) < 0.00001;
  }
}

class CarCoordinateFactoryImplementation {
  public fromArray(coordinate: CoordinateTriplet, offset: CoordinateTriplet = [0, 0, 0]) {
    return new CarCoordinate(coordinate[0] + offset[0], coordinate[1] + offset[1], coordinate[2] + offset[2]);
  }
}

export const CarCoordinateFactory = new CarCoordinateFactoryImplementation();
