import * as d3 from '../../d3-bundle';
import { SVGSelection } from '../../untyped-selection';
import { IMargin } from '../margin';
import { ISize } from '../size';
import { Position } from '../position';
import { SourceData } from '../data-pipeline/types/source-data';
import { IPopulatedMultiPlotSide } from '../data-pipeline/types/i-populated-multi-plot-side';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { AxisRendererUtilities } from './axis-renderer-utilities';

interface IChartSettings {
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly spaceBetweenPlots: number;
}

export enum AxisType {
  columns,
  rows
}

export class AxisRenderer {
  private inner: AxisRendererInner;

  constructor(settings: IChartSettings) {
    this.inner = new AxisRendererInner(settings);
  }

  public render(selection: SVGSelection, disableAnimations: boolean = false): this {
    this.inner.render(selection, disableAnimations);
    return this;
  }

  public layout(value: IPopulatedMultiPlotLayout): this {
    this.inner.layout = value;
    return this;
  }

  public channelData(value: ReadonlyArray<SourceData>): this {
    this.inner.channelData = value;
    return this;
  }

  public axisType(value: AxisType): this {
    this.inner.axisType = value;
    return this;
  }

  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.inner.listeners.on(typenames, callback);
    return this;
  }
}

const AXIS_TRANSITION_TIME = 200;

export class AxisRendererInner {
  public layout?: IPopulatedMultiPlotLayout;
  public channelData?: ReadonlyArray<SourceData>;
  public axisType: AxisType = AxisType.columns;

  public listeners = d3.dispatch('changed');

  constructor(public readonly settings: IChartSettings) {
  }

  public render(selection: SVGSelection, disableAnimations: boolean) {
    if (!this.layout || !this.channelData || !this.settings) {
      return;
    }

    let self = this;

    let transitionTime = disableAnimations ? 0 : AXIS_TRANSITION_TIME;

    let containerClassName = '-axis-renderer';
    let axisClassName = '-axis';
    let axisLayout: IPopulatedMultiPlotSide[];
    if (this.axisType === AxisType.rows) {
      containerClassName = 'row' + containerClassName;
      axisClassName = 'row' + axisClassName;
      axisLayout = this.layout.processed.rows;
    } else {
      containerClassName = 'column' + containerClassName;
      axisClassName = 'column' + axisClassName;
      axisLayout = this.layout.processed.columns;
    }

    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + containerClassName).data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', containerClassName + ' multi-plot-axis-renderer');
    let container = containerEnter.merge(containerUpdate);

    let gUpdate = container.selectAll<SVGGElement, IPopulatedMultiPlotSide>('.' + axisClassName).data(axisLayout);
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g').attr('class', axisClassName + ' axis-parent');
    let g = gEnter.merge(gUpdate);

    let chartPosition = new Position(
      this.settings.svgPadding.left + this.settings.chartMargin.left,
      this.settings.svgPadding.top + this.settings.chartMargin.top);

    if (this.axisType === AxisType.columns) {
      g.attr('transform', (d, i) =>
        'translate('
        + (chartPosition.x + d.processed.offset + this.settings.spaceBetweenPlots)
        + ','
        + (chartPosition.y + this.settings.chartSize.height - this.settings.spaceBetweenPlots)
        + ')');

      let meanPixelSpacingBetweenTicks = 40;

      gEnter.append('g').attr('class', 'axis-grid-lines');
      g.select('.axis-grid-lines').each(function(d: IPopulatedMultiPlotSide) {
        let gridLines = d3.axisBottom(d.processed.scale)
          .tickSize(-(self.settings.chartSize.height - self.settings.spaceBetweenPlots));
        AxisRendererUtilities.formatAxis(gridLines, d, meanPixelSpacingBetweenTicks);

        if (transitionTime) {
          d3.select(this).transition().duration(transitionTime)
            .call(<any>gridLines);
        } else {
          d3.select(this)
            .call(<any>gridLines);
        }
      });

      gEnter.append('g').attr('class', 'axis');
      g.select('.axis').each(function(d: IPopulatedMultiPlotSide) {
        let axis = d3.axisBottom(d.processed.scale);
        AxisRendererUtilities.formatAxis(axis, d, meanPixelSpacingBetweenTicks);

        if (transitionTime) {
          d3.select(this).transition().duration(transitionTime)
            .call(<any>axis);
        } else {
          d3.select(this)
            .call(<any>axis);
        }
      });

      gEnter.append('text').attr('class', 'axis-label');
      g.select('.axis-label')
        .attr('x', (d: IPopulatedMultiPlotSide) => d.processed.plotSize / 2)
        .attr('y', () => 30)
        .attr('text-anchor', 'middle')
        .text((d: IPopulatedMultiPlotSide) => {
          let result = '';
          if (d.processed && d.processed.channels && d.processed.channels.length) {
            let visibleChannels = d.processed.channels.filter(v => v.isVisible);
            if (visibleChannels.length > 0) {
              if (visibleChannels.length > 1) {
                result = 'Multiple Channels';
              } else {
                let visibleChannel = visibleChannels[0];

                result = visibleChannel.name;

                let units = visibleChannel.units;
                if (units && units !== '()') {
                  result += ' (' + units + ')';
                }
              }
            }
          }
          return result;
        })
        .on('click', (_, d: IPopulatedMultiPlotSide) => {
          if (d.processed && d.processed.channels && d.processed.channels.length > 1) {
            let channels = d.processed.channels;
            let visibleChannels = d.processed.channels.filter(v => v.isVisible);
            if (visibleChannels.length > 1) {
              return;
            }

            if (visibleChannels.length === 1) {
              let visibleChannel = visibleChannels[0];
              let visibleChannelIndex = channels.indexOf(visibleChannel);
              channels[visibleChannelIndex].isVisible = false;
              if (visibleChannelIndex === channels.length - 1) {
                channels[0].isVisible = true;
              } else {
                channels[visibleChannelIndex + 1].isVisible = true;
              }
            } else {
              channels[0].isVisible = true;
            }
            this.listeners.call('changed');
          }
        });
    } else {
      g.attr('transform', (d, i) =>
        'translate('
        + (chartPosition.x + this.settings.spaceBetweenPlots)
        + ','
        + (chartPosition.y + d.processed.offset)
        + ')');

      let meanPixelSpacingBetweenTicks = 15;

      gEnter.append('g').attr('class', 'axis-grid-lines');
      g.select('.axis-grid-lines').each(function(d: IPopulatedMultiPlotSide) {
        let gridLines = d3.axisLeft(d.processed.scale)
          .tickSize(-(self.settings.chartSize.width - self.settings.spaceBetweenPlots));
        AxisRendererUtilities.formatAxis(gridLines, d, meanPixelSpacingBetweenTicks);

        if (transitionTime) {
          d3.select(this).transition().duration(transitionTime)
            .call(<any>gridLines);
        } else {
          d3.select(this)
            .call(<any>gridLines);
        }
      });

      gEnter.append('g').attr('class', 'axis');
      g.select('.axis').each(function(d: IPopulatedMultiPlotSide) {
        let axis = d3.axisLeft(d.processed.scale);
        AxisRendererUtilities.formatAxis(axis, d, meanPixelSpacingBetweenTicks);

        if (transitionTime) {
          d3.select(this).transition().duration(transitionTime)
            .call(<any>axis);
        } else {
          d3.select(this)
            .call(<any>axis);
        }
      });
    }
  }

}
