import * as d3 from '../../d3-bundle';
import { MultiPlotViewerSettings } from './multi-plot-viewer-settings';
import { Utilities } from '../../utilities';
import { HTMLDivSelection, SVGSelection } from '../../untyped-selection';
import { AxisRenderer, AxisType } from './axis-renderer';
import { SourceLabelsRenderer } from '../components/source-labels-renderer';
import { Margin } from '../margin';
import { ILegendChannel, ILegendList, LegendRenderer, RenderOrientation } from '../components/legend-renderer';
import { SourceData } from '../data-pipeline/types/source-data';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { IMultiPlotLayout } from '../data-pipeline/types/i-multi-plot-layout';
import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { IEditChannelsType, NavigationStationViewer } from '../../navigation-station/navigation-station-viewer';
import { Subject, Subscription } from 'rxjs';
import { IEditChannelsOptions, PaneChannelLayout, SiteHooks } from '../../site-hooks';
import { ISourceLoader } from '../channel-data-loaders/source-loader';
import { CsvSourceLoader } from '../channel-data-loaders/csv-source-loader';
import { ChannelNameStyle } from '../channel-data-loaders/channel-name-style';
import { SourceLoaderUtilities } from '../channel-data-loaders/source-loader-utilities';
import { MultiPlotDataRendererBase } from './multi-plot-data-renderer-base';
import { SharedState } from '../shared-state';
import { SourceLoaderSet, SourceLoaderViewModel } from '../channel-data-loaders/source-loader-set';
import { PaneResizeHandlesRenderer, PaneResizeHandlesType } from './pane-resize-handles-renderer';
import { ZoomRenderer } from './zoom-renderer';
import { PlotClippingRenderer } from './plot-clipping-renderer';
import {
  DOMAIN_EVENT_PROCESSED_EVENT,
  DomainEventHandler,
  LEGEND_CHANGED_EVENT,
  ProcessedDomainEvent
} from './domain-event-handler';
import { ILegendSettings } from '../legend-settings';
import { DataPipeline } from '../data-pipeline/data-pipeline';

export const EDIT_TYPE_COLUMNS = 'columns';
export const EDIT_TYPE_ROWS = 'rows';

export abstract class MultiPlotViewerBase implements NavigationStationViewer {
  public errorEvent: Subject<string> = new Subject<string>();

  protected elementId?: string;
  protected settings?: MultiPlotViewerSettings;
  private parent?: HTMLDivSelection;
  protected svg?: SVGSelection;

  protected sourceData?: ReadonlyArray<SourceData>;

  protected xAxisRenderer?: AxisRenderer;
  protected yAxisRenderer?: AxisRenderer;
  protected xHandlesRenderer?: PaneResizeHandlesRenderer;
  protected yHandlesRenderer?: PaneResizeHandlesRenderer;
  protected plotClippingRenderer?: PlotClippingRenderer;
  protected dataRenderer?: MultiPlotDataRendererBase;
  protected rowLegendRenderer?: LegendRenderer;
  protected sourceLabelsRenderer?: SourceLabelsRenderer;
  protected zoomRenderer?: ZoomRenderer;
  protected domainEventHandler?: DomainEventHandler;

  private subscriptions: Subscription = new Subscription();

  protected readonly sourceLoaderSet: SourceLoaderSet;
  protected xLegendList?: ColumnLegendList;

  protected constructor(
    protected readonly primaryDomainName: string,
    protected layout: IMultiPlotLayout,
    protected readonly channelNameStyle: ChannelNameStyle,
    protected readonly dataPipeline: DataPipeline,
    protected readonly sharedState: SharedState,
    protected readonly siteHooks: SiteHooks) {
    this.sourceLoaderSet = this.sharedState.sourceLoaderSet;
  }

  protected get populatedLayout(): IPopulatedMultiPlotLayout {
    return this.layout as IPopulatedMultiPlotLayout;
  }

  public abstract createSettings(sourceCount: number): MultiPlotViewerSettings;

  public abstract createDataRenderer(): MultiPlotDataRendererBase;

  protected abstract getCssClass(): string;

  public async build(elementId: string) {
    this.elementId = elementId;

    await this.loadFromLayout(this.layout);

    if (!this.exists || !this.sourceData) {
      return;
    }

    let sourceCount = this.sourceData.length;
    this.settings = this.createSettings(sourceCount);
    this.settings.svgSize = Utilities.getRequiredSvgSize(this.elementId, this.settings.svgSize);
    this.processLayout();
    this.updateChartMargin();

    this.parent = this.select()
      .append<HTMLDivElement>('div')
      .attr('class', `multi-plot-viewer-container ${this.getCssClass()}-container canvas-viewer`);

    this.svg = this.parent
      .append<SVGElement>('svg')
      .attr('width', this.settings.svgSize.width)
      .attr('height', this.settings.svgSize.height)
      .attr('class', `multi-plot-viewer ${this.getCssClass()}`)
      .attr('id', 'svg');

    // Create Source Labels
    this.sourceLabelsRenderer = new SourceLabelsRenderer(this.settings);
    this.sourceLabelsRenderer
      .sharedState(this.sharedState);

    // Create Axis Renderer.
    this.xAxisRenderer = new AxisRenderer(this.settings);
    this.yAxisRenderer = new AxisRenderer(this.settings);

    this.xAxisRenderer
      .axisType(AxisType.columns)
      .on('changed', () => this.renderAll(true));

    this.yAxisRenderer
      .axisType(AxisType.rows)
      .on('changed', () => this.renderAll(true));

    // Create Axis Handles.
    this.xHandlesRenderer = new PaneResizeHandlesRenderer();
    this.yHandlesRenderer = new PaneResizeHandlesRenderer();

    this.xHandlesRenderer
      .paneResizeHandlesType(PaneResizeHandlesType.columns)
      .chartSettings(this.settings)
      .on('changed', () => this.renderAll(true, true)); // TODO: false, true? Breaks when zoomed.

    this.yHandlesRenderer
      .paneResizeHandlesType(PaneResizeHandlesType.rows)
      .chartSettings(this.settings)
      .on('changed', () => this.renderAll(true, true)); // TODO: false, true? Breaks when zoomed.

    // Create Plot Clipping Renderer.
    this.plotClippingRenderer = new PlotClippingRenderer();

    this.plotClippingRenderer.chartSettings(this.settings);

    // Create Legend Renderer.
    this.rowLegendRenderer = new LegendRenderer();
    this.rowLegendRenderer
      .chartSettings(this.settings)
      .orientation(RenderOrientation.vertical)
      .siteHooks(this.siteHooks)
      .setError((message) => this.setError(message))
      .on('changed', () => this.renderAll(true));

    this.xLegendList = new ColumnLegendList(this.settings.legend, -this.settings.spaceBetweenPlots / 2);

    // Create Data Renderer.
    const dataRenderer = this.dataRenderer = this.createDataRenderer();

    this.dataRenderer
      .chartSettings(this.settings)
      .canvasData({
        parent: this.parent,
        settings: this.settings
      })
      .primaryDomainName(this.primaryDomainName)
      .xLegendList(this.xLegendList)
      .sharedState(this.sharedState)
      .on('legendChanged', () => this.onLegendChanged(false));

    this.zoomRenderer = new ZoomRenderer();

    this.zoomRenderer
      .chartSettings(this.settings)
      .sharedState(this.sharedState)
      .xLegendList(this.xLegendList)
      .domainSnapBehaviour(this.dataRenderer.getDomainSnapBehaviour())
      .on('zoom', () => this.renderAll(true, true))
      .on('legendChanged', () => this.onLegendChanged(true));

    this.domainEventHandler = new DomainEventHandler(this.sharedState, this.primaryDomainName);
    this.domainEventHandler
      .setXLegendList(this.xLegendList)
      .setDomainSnapBehaviour(this.dataRenderer.getDomainSnapBehaviour())
      .on(LEGEND_CHANGED_EVENT, () => this.onLegendChanged(false))
      .on(DOMAIN_EVENT_PROCESSED_EVENT, (v: ProcessedDomainEvent) => dataRenderer.domainEventProcessed(v));
    this.domainEventHandler.build();

    this.onBuilt();

    this.renderAll(true);

    this.subscriptions = this.sharedState.windowResizeNews.subscribe((code: number) => this.resizeChart());
    this.subscriptions.add(this.sourceLoaderSet.changed.subscribe(() => this.handleSourcesChanged()));
  }

  protected onBuilt() {
  }

  protected onDataSet() {
  }

  protected onRendered() {
  }

  private updateChartMargin() {
    if (!this.settings) {
      return;
    }

    let sourceCount = this.settings.sourceCount;
    let legendLabels: string[] =
      [...this.populatedLayout.rows, ...this.populatedLayout.columns].reduce<string[]>((p, c) => [...p, ...c.processed.channels.map(v => v.name)], []);

    this.settings.chartMargin = new Margin(
      SourceLabelsRenderer.getRequiredVerticalSpace(sourceCount) || 20,
      this.settings.legend.getRequiredHorizontalSpace(sourceCount, legendLabels),
      30,
      40);
  }

  public dispose() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }

    if (this.dataRenderer) {
      this.dataRenderer.dispose();
    }

    if (this.zoomRenderer) {
      this.zoomRenderer.dispose();
    }

    if (this.domainEventHandler) {
      this.domainEventHandler.dispose();
    }
  }

  protected onLegendChanged(isZoomCalculations: boolean) {
    if (!this.zoomRenderer || !this.svg || !this.rowLegendRenderer || !this.parent) {
      return;
    }

    if (!isZoomCalculations && this.zoomRenderer.isRenderingCalculations) {
      return;
    }

    this.rowLegendRenderer.render(this.svg, this.parent);
  }

  private resizeChart() {
    try {
      if (!this.settings || !this.svg || !this.elementId) {
        return;
      }

      this.settings.svgSize = Utilities.getRequiredSvgSize(this.elementId, this.settings.svgSize);
      this.svg.attr('width', this.settings.svgSize.width);
      this.svg.attr('height', this.settings.svgSize.height);

      // If the layout is in the middle of being updated, we may not be able to render.
      if (this.layout && this.layout.processed) {
        this.renderAll(true);
      }
    } catch (error) {
      this.setError(this.siteHooks.getFriendlyErrorAndLog(error));
    }
  }

  protected async loadFromLayout(inputLayout?: IMultiPlotLayout) {
    if (inputLayout) {
      upgradeMultiPlotViewerLayout(inputLayout);
      this.layout = await this.dataPipeline.getValidatedLayout.execute(inputLayout);
    }

    this.sourceData = await this.dataPipeline.loadChannelData.execute(this.layout);
  }

  protected setAllData() {
    if (!this.sourceData || !this.xLegendList) {
      return;
    }

    if (this.sourceLabelsRenderer) {
      this.sourceLabelsRenderer.sources(this.sourceData);
    }

    if (this.xAxisRenderer) {
      this.xAxisRenderer.layout(this.populatedLayout).channelData(this.sourceData);
    }

    if (this.yAxisRenderer) {
      this.yAxisRenderer.layout(this.populatedLayout).channelData(this.sourceData);
    }

    if (this.xHandlesRenderer) {
      this.xHandlesRenderer.layout(this.populatedLayout);
    }

    if (this.yHandlesRenderer) {
      this.yHandlesRenderer.layout(this.populatedLayout);
    }

    if (this.plotClippingRenderer) {
      this.plotClippingRenderer.layout(this.populatedLayout);
    }

    if (this.rowLegendRenderer) {
      this.rowLegendRenderer.data([...this.populatedLayout.processed.rows.map(v => v.processed), this.xLegendList]);
    }

    if (this.dataRenderer) {
      this.dataRenderer.layout(this.populatedLayout).sourceData(this.sourceData);
    }

    if (this.zoomRenderer) {
      this.zoomRenderer.layout(this.populatedLayout);
    }

    if (this.domainEventHandler) {
      this.domainEventHandler.setLayout(this.populatedLayout).setSourceData(this.sourceData);
    }

    this.onDataSet();
  }

  protected processLayout() {
    if (!this.sourceData) {
      return;
    }

    this.dataPipeline.processLayout.execute(this.layout, this.sourceData);
  }

  protected renderAll(processLayout: boolean, disableAnimations: boolean = false) {
    if (!this.exists || !this.svg || !this.settings || !this.parent) {
      return;
    }

    if (processLayout) {
      this.processLayout();
      this.setAllData();
    }

    this.updateChartMargin();
    this.dataPipeline.updatePlotSizes.execute(this.populatedLayout, this.settings);

    if (this.zoomRenderer) {
      this.zoomRenderer.preRender(this.svg);
    }

    this.dataPipeline.updatePlotSizes.execute(this.populatedLayout, this.settings);

    if (this.sourceLabelsRenderer) {
      this.sourceLabelsRenderer.render(this.svg);
    }

    if (this.xAxisRenderer) {
      this.xAxisRenderer.render(this.svg, disableAnimations);
    }

    if (this.yAxisRenderer) {
      this.yAxisRenderer.render(this.svg, disableAnimations);
    }

    if (this.xHandlesRenderer) {
      this.xHandlesRenderer.render(this.svg);
    }

    if (this.yHandlesRenderer) {
      this.yHandlesRenderer.render(this.svg);
    }

    if (this.plotClippingRenderer) {
      this.plotClippingRenderer.render(this.svg);
    }

    if (this.dataRenderer) {
      this.dataRenderer.render(this.svg);
    }

    if (this.rowLegendRenderer) {
      this.rowLegendRenderer.render(this.svg, this.parent);
    }

    if (this.zoomRenderer) {
      this.zoomRenderer.render(this.svg);
    }

    if (this.domainEventHandler) {
      this.domainEventHandler.render();
    }

    this.onRendered();
  }

  protected async addLoader(loader: ISourceLoader) {
    try {
      this.sharedState.sourceLoaderSet.add(new SourceLoaderViewModel(loader));
    } catch (error) {
      this.setError(this.siteHooks.getFriendlyErrorAndLog(error));
    }
  }

  protected async handleSourcesChanged() {
    try {
      if (!this.settings || !this.sourceData) {
        return;
      }

      await this.loadFromLayout();

      this.settings.sourceCount = this.sourceData.length;

      this.renderAll(true);
    } catch (error) {
      this.setError(this.siteHooks.getFriendlyErrorAndLog(error));
    }
  }

  protected setError(message: string) {
    this.errorEvent.next(message);
  }

  public getLayout() {
    return this.dataPipeline.getUnprocessedLayout.execute(this.layout);
  }

  public async setLayout(layout: any): Promise<any> {
    await this.loadFromLayout(layout);
    this.renderAll(true);
  }

  public canImportData(): boolean {
    return true;
  }

  public canExportData(): boolean {
    return true;
  }

  public canStackDiagonals(): boolean {
    return true;
  }

  public importCsvData(name: string, fileContent: string) {
    this.addLoader(CsvSourceLoader.create(name, fileContent, this.siteHooks));
  }

  public async exportCsvData() {
    let sources = this.sourceLoaderSet.sources;
    for (let sourceIndex = 0; sourceIndex < sources.length; ++sourceIndex) {
      let source = sources[sourceIndex];

      // write the column headers (channel name and units below)
      let channelNamesLine = '';
      let unitsLine = '';

      let allChannels: ProcessedMultiPlotChannel[] = [];
      allChannels.push(this.populatedLayout.processed.primaryDomain);
      for (let side of [...this.populatedLayout.columns, ...this.populatedLayout.rows]) {
        for (let channel of side.processed.channels) {
          if (allChannels.every(v => v.name !== channel.name)) {
            allChannels.push(channel);
          }
        }
      }

      let channelCount = allChannels.length;

      for (let channel of allChannels) {
        channelNamesLine += channel.name + ',';
        unitsLine += channel.units + ',';
      }

      let sourceMetadata = await source.getSourceMetadata();
      let csvContent = '"' + sourceMetadata.name + '"';
      for (let i = 1; i < channelCount; i++) {
        csvContent += ',';
      }
      csvContent += '\n';
      csvContent += channelNamesLine.replace(/.$/, '\n');
      csvContent += unitsLine.replace(/.$/, '\n');

      // write the data
      let dataLength = 0;
      let channelsWithData = allChannels.filter(c => c.sources[sourceIndex] && c.sources[sourceIndex].data && c.sources[sourceIndex].data.length);
      if (channelsWithData.length) {
        dataLength = channelsWithData[0].sources[sourceIndex].data.length;
      }
      for (let i = 0; i < dataLength; i++) {
        for (let channel of allChannels) {
          let source = channel.sources[sourceIndex];
          if (source && source.data && source.data.length > i) {
            csvContent += source.data[i];
          }
          csvContent += ',';
        }
        csvContent = csvContent.replace(/.$/, '\n');
      }

      Utilities.download(sourceMetadata.name + '.csv', csvContent);
    }
  }

  public getEditChannelsTypes(): IEditChannelsType[] {
    return [
      {
        name: 'Edit Columns',
        icon: 'navicon fa-rotate-90',
        id: EDIT_TYPE_COLUMNS
      },
      {
        name: 'Edit Rows',
        icon: 'navicon',
        id: EDIT_TYPE_ROWS
      }
    ];
  }

  public async getEditChannelsOptions(editType: IEditChannelsType): Promise<IEditChannelsOptions> {
    let layout = this.getLayout();
    let channels = await SourceLoaderUtilities.getDistinctRequestableChannels(this.sourceLoaderSet.sources, this.channelNameStyle, this.primaryDomainName);
    let isColumns = editType.id === EDIT_TYPE_COLUMNS;
    return {
      channels,
      panes: isColumns ? layout.columns : layout.rows,
      oneChannelPerPane: false
    };
  }

  public async setEditChannelsResult(editType: IEditChannelsType, result: PaneChannelLayout): Promise<void> {
    let layout = this.getLayout();
    let side = result.map(v => ({
      channels: v.channels,
      relativeSize: v.relativeSize || 1,
      processed: undefined
    }));

    switch (editType.id) {
      case EDIT_TYPE_COLUMNS:
        layout.columns = side;
        break;

      case EDIT_TYPE_ROWS:
        layout.rows = side;
        break;

      default:
        throw new Error('Unexpected edit type: ' + editType.id);
    }

    await this.setLayout(layout);
  }

  protected select(selection: string = '') {
    return d3.select('#' + this.elementId + ' ' + selection);
  }

  protected selectAll(selection: string = '') {
    return d3.selectAll('#' + this.elementId + ' ' + selection);
  }

  protected get exists(): boolean {
    return !!(this.elementId && document.getElementById(this.elementId));
  }
}

export function upgradeMultiPlotViewerLayout(layout: any) {

  // NOTE: This also happens now as part of a document upgrade in the API,
  // but we're keeping this here for safety and because local chart JSONs use it.
  // Remove it if you don't think it's needed anymore.
  delete layout.name;
  delete layout.xDomain;

  if (layout.columns) {
    delete layout.panes;
    delete layout.xDomains;
    return;
  }

  layout.rows = layout.panes;
  layout.rows.forEach((v: any) => delete v.units);
  layout.columns = [
    {
      channels: layout.xDomains,
      relativeSize: 1
    }
  ];

  delete layout.panes;
  delete layout.xDomains;
}

export class ColumnLegendList implements ILegendList {
  public readonly _channels: ILegendChannel[];
  public readonly disableToggleVisibility?: boolean;

  private readonly legendChannelHeight: number;

  constructor(
    private readonly legendSettings: ILegendSettings,
    private readonly _size: number) {
    this._channels = [];
    this.disableToggleVisibility = true;
    this.legendChannelHeight = this.legendSettings.blobSize + this.legendSettings.blobSpacing;
  }

  public get size(): number {

    return this._size - (Math.max(0, this._channels.length - 4) * this.legendChannelHeight);
  }

  public get channels(): ReadonlyArray<ILegendChannel> {
    return this._channels;
  }

  public addChannel(channel: ILegendChannel) {
    this._channels.push(channel);
  }

  public clearChannels() {
    this._channels.length = 0;
  }
}
