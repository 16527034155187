import { Box3, GridHelper, Group, Scene, Color } from 'three';
import * as d3 from '../../d3-bundle';
import { modulo } from '../../modulo';

export class FloorRenderer {

  private floor: Group | undefined;
  private floorSize: number = 1;

  private readonly carBoundingBox = new Box3();
  private contentGroup: Group | undefined;

  constructor(
    private readonly scene: Scene,
    private readonly divisionsPerMeter: number = 1,
    private readonly verticalOffset: number = 0) {
  }

  public build(contentGroup: Group) {
    this.contentGroup = contentGroup;
    this.update();
  }

  public update() {
    let size = 2;
    if (this.contentGroup && this.contentGroup.children.length) {
      this.carBoundingBox.setFromObject(this.contentGroup);
      let extent = Math.ceil(d3.maxStrict([
        this.carBoundingBox.max.x,
        this.carBoundingBox.max.z,
        this.carBoundingBox.min.x,
        this.carBoundingBox.min.z].map((v => Math.abs(v)))));
      let metresPerDivision = 1 / this.divisionsPerMeter;
      if (metresPerDivision > 1) {
        extent += metresPerDivision - modulo(extent, metresPerDivision);
      }
      size = 2 * extent;
    }

    if (this.floor) {
      if (size !== this.floorSize) {
        this.scene.remove(this.floor);
        this.floor = undefined;
      }
    }

    if (!this.floor) {
      let style = getComputedStyle(document.documentElement);
      let divisions = size * this.divisionsPerMeter;
      let grid = new GridHelper(
        size,
        divisions,
        new Color(`${style.getPropertyValue('--grid-centreline-colour')}`),
        new Color(`${style.getPropertyValue('--grid-colour')}`));
      this.floor = new Group();
      this.floor.add(grid);
      this.floor.position.setY(this.verticalOffset);

      this.floorSize = size;
      this.scene.add(this.floor);
    }
  }
}
