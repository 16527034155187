
export const NO_PRIMARY_DOMAIN_SPECIFIED_ERROR_MESSAGE = 'No primary domain specified.';

export class CanStackDiagonals {
  public static execute(rowCount: number, columnCount: number): boolean {
    if (rowCount !== 1 && columnCount !== 1) {
      if (rowCount === columnCount) {
        return true;
      } else if ((rowCount % columnCount) === 0) {
        return true;
      } else if ((columnCount % rowCount) === 0) {
        return true;
      }
    }

    return false;
  }
}


