import { SharedState } from '../shared-state';
import { Group, Mesh, MeshPhongMaterial, Raycaster, SphereGeometry } from 'three';
import * as d3 from '../../d3-bundle';
import { BaseType } from 'd3-selection';
import { LoadedConfig } from './load-configs-for-sources';
import { ChartSettings } from '../chart-settings';
import { Subscription } from 'rxjs';
import { SVGSelection } from '../../untyped-selection';

export class RenderedConfig {
  constructor(
    public readonly sourceIndex: number,
    public readonly group: Group) { }
}

export abstract class ConfigRendererBase {
  protected readonly renderedConfigs: { [id: string]: RenderedConfig } = {};
  protected settings!: ChartSettings;
  protected group!: Group;
  protected svg!: SVGSelection;
  protected configs!: ReadonlyArray<LoadedConfig>;

  protected updateAll: boolean = false;

  protected readonly subscriptions: Subscription = new Subscription();

  constructor(
    protected readonly sharedState: SharedState) {
  }

  public dispose() {
    this.subscriptions.unsubscribe();
  }

  protected abstract performBuild(): void;
  protected abstract performUpdate(mouseRaycaster: Raycaster | undefined): void;
  protected abstract renderConfig(sourceIndex: number, configData: any): RenderedConfig;

  public build(
    settings: ChartSettings,
    svg: d3.Selection<SVGElement, any, BaseType, any>,
    configs: ReadonlyArray<LoadedConfig>): Group {

    this.settings = settings;
    this.svg = svg;
    this.configs = configs;
    this.group = new Group();

    // This avoids crashing if group ends up empty.
    this.createDummyObject(this.group);

    this.performBuild();

    this.update(undefined);
    return this.group;
  }

  public update(mouseRaycaster: Raycaster | undefined): boolean {
    let sceneModified = false;

    let usedIds: { [id: string]: boolean } = {};
    for (let sourceIndex = 0; sourceIndex < this.configs.length; ++sourceIndex) {
      let config = this.configs[sourceIndex];
      if (config) {
        usedIds[config.id] = true;
        let renderedConfig: RenderedConfig | undefined = this.renderedConfigs[config.id];

        if (renderedConfig && (renderedConfig.sourceIndex !== sourceIndex || this.updateAll)) {
          sceneModified = true;
          this.group.remove(renderedConfig.group);
          delete this.renderedConfigs[config.id];
          renderedConfig = undefined;
        }

        if (!renderedConfig && config.data) {
          sceneModified = true;
          renderedConfig = this.renderedConfigs[config.id] = this.renderConfig(sourceIndex, config.data);
          if (renderedConfig.group.children.length) {
            this.group.add(renderedConfig.group);
          }
        }

        if (renderedConfig) {
          renderedConfig.group.visible = this.sharedState.sourceLoaderSet.sources[sourceIndex].isVisible;
        }
      }
    }

    for (let renderedConfigId in this.renderedConfigs) {
      if (!usedIds[renderedConfigId]) {
        sceneModified = true;
        let renderedConfig = this.renderedConfigs[renderedConfigId];
        delete this.renderedConfigs[renderedConfigId];
        this.group.remove(renderedConfig.group);
      }
    }

    if (sceneModified) {
      // Update render orders to ensure first configs are rendered on top.
      for (let sourceIndex = 0; sourceIndex < this.configs.length; ++sourceIndex) {
        let config = this.configs[sourceIndex];
        if (config) {
          let renderedConfig: RenderedConfig = this.renderedConfigs[config.id];
          if (renderedConfig) {
            renderedConfig.group.traverse(v => v.renderOrder -= (sourceIndex * 1000));
          }
        }
      }
    }

    this.performUpdate(mouseRaycaster);

    this.updateAll = false;

    return sceneModified;
  }

  private createDummyObject(group: Group): Mesh {
    const radius = 1;
    let geometry = new SphereGeometry(radius, 10, 10);
    let material = new MeshPhongMaterial({ color: 0x888888, transparent: true, opacity: 0, flatShading: false, specular: 0x444444 });
    let marker = new Mesh(geometry, material);
    marker.userData.ignoreIntersection = true;
    group.add(marker);
    return marker;
  }

  public groupWithoutIgnoredObjects(group: Group) {
    let result = new Group();
    result.add(...group.children.filter(v => !v.userData.ignoreIntersection).map(v => v.clone(true)));
    return result;
  }
}
