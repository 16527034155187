import * as d3 from '../d3-bundle';

export interface ILegendSettingsBase {
  readonly blobSize: number;     // pixel size of coloured squares in legend
  readonly blobSpacing: number;  // pixel gap between coloured squares in lengend
  readonly valueWidth: number;   // pixel width of legend value boxes
  readonly unitsWidth: number;   // pixel width of legend value units display
  readonly maximumLabelWidth: number;   // pixel width of channel name in legend
  renderChannelCount: number; // Number of "render channels" (i.e. size channel, color channel, etc).
  readonly renderChannelWidth: number;   // pixel width of channel name in legend
}

export interface ILegendSettings extends ILegendSettingsBase {
  getRequiredHorizontalSpace(sourceCount: number, labels?: string[], renderChannelCount?: number): number;
  getRequiredVerticalSpace(channelCount: number): number;
  trimLabel(label: string): string;
}

export const AVERAGE_PIXELS_PER_CHARACTER = 5.8;

export class LegendSettings implements ILegendSettings {

  private _blobSize: number;
  private _blobSpacing: number;
  private _valueWidth: number;
  private _unitsWidth: number;
  private _maximumLabelWidth: number;
  private _renderChannelCount: number;

  constructor(value: ILegendSettingsBase) {
    this._blobSize = value.blobSize;
    this._blobSpacing = value.blobSpacing;
    this._valueWidth = value.valueWidth;
    this._unitsWidth = value.unitsWidth;
    this._maximumLabelWidth = value.maximumLabelWidth;
    this._renderChannelCount = value.renderChannelCount;
  }

  public get blobSize(): number {
    return this._blobSize;
  }

  public get blobSpacing(): number {
    return this._blobSpacing;
  }

  public get valueWidth(): number {
    return this._valueWidth;
  }

  public get unitsWidth(): number {
    return this._unitsWidth;
  }

  public get maximumLabelWidth(): number {
    return this._maximumLabelWidth;
  }

  public get renderChannelCount(): number {
    return this._renderChannelCount;
  }

  public set renderChannelCount(value: number) {
    this._renderChannelCount = value;
  }

  public get renderChannelWidth(): number {
    return this.renderChannelCount * (this.blobSize + this.blobSpacing);
  }

  public getRequiredHorizontalSpace(sourceCount: number, labels?: string[]): number {
    let requiredLabelWidth = 50; // Dome default value.

    if (labels && labels.length) {
      requiredLabelWidth = d3.maxStrict([
        40,
        d3.maxStrict(labels.map(v => v.length)) * AVERAGE_PIXELS_PER_CHARACTER]);
    }

    requiredLabelWidth = d3.minStrict([requiredLabelWidth, this._maximumLabelWidth]);

    return sourceCount * this.valueWidth
      + this.unitsWidth
      + this.blobSize
      + requiredLabelWidth
      + this.renderChannelWidth;
  }

  public trimLabel(label: string): string {
    if (!label) {
      return label;
    }

    let maximumCharacters = Math.ceil(this._maximumLabelWidth / AVERAGE_PIXELS_PER_CHARACTER);
    if (label.length <= maximumCharacters) {
      return label;
    }

    return '…' + label.substr(label.length - maximumCharacters, maximumCharacters);
  }

  public getRequiredVerticalSpace(channelCount: number): number {
    return channelCount * this.blobSize;
  }
}
