import { SharedState } from '../shared-state';

export async function loadConfigsForSources(
  sharedState: SharedState,
  configType: string,
  removeDuplicates: boolean): Promise<any[]> {

  let configTasks: Promise<LoadedConfig>[] = sharedState.sourceLoaderSet.sources.map(async (source): Promise<LoadedConfig> => {
    let data = await source.getConfig(configType);
    return new LoadedConfig(source.id, data);
  });

  let configs = await Promise.all(configTasks) as (LoadedConfig | undefined)[];

  if (removeDuplicates) {
    let duplicateConfigs = new Set<any>();
    for (let i = 0; i < configs.length; ++i) {
      let config = configs[i];
      if (config) {
        if (duplicateConfigs.has(config.data)) {
          configs[i] = undefined;
        } else {
          duplicateConfigs.add(config.data);
        }
      }
    }
  }

  return configs;
}

export class LoadedConfig {
  constructor(
    public readonly id: string,
    public readonly data: any) {
  }
}
