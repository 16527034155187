import request from "./request";
export default function (defaultMimeType, parse) {
  return function (url, row, callback) {
    if (arguments.length < 3) callback = row, row = null;
    var r = request(url).mimeType(defaultMimeType);
    r.row = function (_) {
      return arguments.length ? r.response(responseOf(parse, row = _)) : row;
    };
    r.row(row);
    return callback ? r.get(callback) : r;
  };
}
function responseOf(parse, row) {
  return function (request) {
    return parse(request.responseText, row);
  };
}