import * as d3 from '../../d3-bundle';
import { SVGSelection } from '../../untyped-selection';
import { IMargin } from '../margin';
import { ISize } from '../size';
import { Position } from '../position';
import { IPopulatedMultiPlotSide } from '../data-pipeline/types/i-populated-multi-plot-side';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';

const HANDLE_LENGTH = 30;
const MINIMUM_PANE_SIZE = 20;

interface IChartSettings {
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly spaceBetweenPlots: number;
}

export enum PaneResizeHandlesType {
  columns,
  rows
}

export class PaneResizeHandlesRenderer {
  private inner = new PaneResizeHandlesRendererInner();

  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  public layout(value: IPopulatedMultiPlotLayout): this {
    this.inner.layout = value;
    return this;
  }

  public chartSettings(value: IChartSettings): this {
    this.inner.settings = value;
    return this;
  }

  public paneResizeHandlesType(value: PaneResizeHandlesType): this {
    this.inner.paneResizeHandlesType = value;
    return this;
  }

  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.inner.listeners.on(typenames, callback);
    return this;
  }
}

export class PaneResizeHandlesRendererInner {
  public layout?: IPopulatedMultiPlotLayout;
  public settings?: IChartSettings;
  public paneResizeHandlesType: PaneResizeHandlesType = PaneResizeHandlesType.columns;

  public listeners = d3.dispatch('changed');

  public render(selection: SVGSelection) {
    if (!this.layout || !this.settings) {
      return;
    }

    const settings = this.settings;

    let self = this;

    let containerClassName = '-pane-resize-handles-renderer';
    let paneResizeHandleClassName = '-pane-resize-handle';
    let side: IPopulatedMultiPlotSide[];
    if (this.paneResizeHandlesType === PaneResizeHandlesType.rows) {
      containerClassName = 'row' + containerClassName;
      paneResizeHandleClassName = 'row' + paneResizeHandleClassName;
      side = this.layout.processed.rows;
    } else {
      containerClassName = 'column' + containerClassName;
      paneResizeHandleClassName = 'column' + paneResizeHandleClassName;
      side = this.layout.processed.columns;
    }

    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + containerClassName).data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', containerClassName + ' multi-plot-pane-resize-handles-renderer');
    let container = containerEnter.merge(containerUpdate);

    let panePairs: SidePair[] = [];
    for (let i = 1; i < side.length; ++i) {
      panePairs.push([side[i - 1], side[i]]);
    }

    let gUpdate = container.selectAll<SVGGElement, SidePair>('.' + paneResizeHandleClassName).data(panePairs);
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g').attr('class', paneResizeHandleClassName + ' pane-resize-handle-parent');
    let g = gEnter.merge(gUpdate);

    let chartPosition = new Position(
      settings.svgPadding.left + settings.chartMargin.left,
      settings.svgPadding.top + settings.chartMargin.top);

    if (this.paneResizeHandlesType === PaneResizeHandlesType.columns) {
      g.attr('transform', (d, i) =>
        'translate('
        + (chartPosition.x + d[1].processed.offset)
        + ','
        + (chartPosition.y)
        + ')');

      gEnter.append('rect').attr('class', 'handle')
        .attr('width', settings.spaceBetweenPlots)
        .attr('height', HANDLE_LENGTH + settings.chartSize.height);
    } else {
      g.attr('transform', (d, i) =>
        'translate('
        + (chartPosition.x - HANDLE_LENGTH)
        + ','
        + (chartPosition.y + d[1].processed.offset - settings.spaceBetweenPlots)
        + ')');

      gEnter.append('rect').attr('class', 'handle')
        .attr('width', HANDLE_LENGTH + settings.chartSize.width)
        .attr('height', settings.spaceBetweenPlots);
    }

    gEnter
      .on('mousedown', function(currentEvent: any, d: SidePair) {
        let mouseEvent = currentEvent;
        mouseEvent.preventDefault();
        let dragData = new DragData(
          mouseEvent,
          [
            new InitialSize(d[0].relativeSize, d[0].processed.plotSize),
            new InitialSize(d[1].relativeSize, d[1].processed.plotSize)
          ]);
        self.paneResizeStart(selection, d, dragData);
      });
  }

  protected paneResizeStart(trackingSelection: SVGSelection, pair: SidePair, dragData: DragData) {
    trackingSelection
      .on('mousemove.pane-resize-handle', e => this.paneResizeDuring(trackingSelection, pair, dragData, e))
      .on('mouseup.pane-resize-handle', () => this.paneResizeEnd(trackingSelection, pair, dragData))
      .on('mouseleave.pane-resize-handle', () => {
        //if(currentEvent.currentTarget === trackingSelection) {
        this.paneResizeEnd(trackingSelection, pair, dragData);
        //}
      });
  }

  protected paneResizeDuring(trackingSelection: SVGSelection, pair: SidePair, dragData: DragData, currentEvent: MouseEvent) {
    let mouseEvent = currentEvent;
    //mouseEvent.preventDefault();

    let change = this.paneResizeHandlesType === PaneResizeHandlesType.columns
      ? mouseEvent.pageX - dragData.mouseEvent.pageX
      : mouseEvent.pageY - dragData.mouseEvent.pageY;

    let initialFirst = dragData.pair[0];
    let initialSecond = dragData.pair[1];
    let relativeSizeTotal = initialFirst.relativeSize + initialSecond.relativeSize;
    let absoluteSizeTotal = initialFirst.absoluteSize + initialSecond.absoluteSize;

    if (absoluteSizeTotal <= (2 * MINIMUM_PANE_SIZE)) {
      // The panes are too small already, just split them equally.
      pair[0].relativeSize = relativeSizeTotal / 2;
      pair[1].relativeSize = relativeSizeTotal / 2;
    } else {
      let firstPaneNewAbsoluteSize = initialFirst.absoluteSize + change;

      if (firstPaneNewAbsoluteSize < MINIMUM_PANE_SIZE) {
        firstPaneNewAbsoluteSize = MINIMUM_PANE_SIZE;
      }

      if (firstPaneNewAbsoluteSize > (absoluteSizeTotal - MINIMUM_PANE_SIZE)) {
        firstPaneNewAbsoluteSize = absoluteSizeTotal - MINIMUM_PANE_SIZE;
      }

      let firstPaneChangeRatio = firstPaneNewAbsoluteSize / initialFirst.absoluteSize;
      let firstPaneNewRelativeSize = initialFirst.relativeSize * firstPaneChangeRatio;
      let secondPaneNewRelativeSize = relativeSizeTotal - firstPaneNewRelativeSize;

      pair[0].relativeSize = firstPaneNewRelativeSize;
      pair[1].relativeSize = secondPaneNewRelativeSize;
    }

    this.listeners.call('changed');
  }

  protected paneResizeEnd(trackingSelection: SVGSelection, pair: SidePair, dragData: DragData) {
    trackingSelection
      .on('mousemove.pane-resize-handle', null)
      .on('mouseup.pane-resize-handle', null)
      .on('mouseleave.pane-resize-handle', null);
  }
}

class InitialSize {
  constructor(
    public relativeSize: number,
    public absoluteSize: number) {
  }
}

class DragData {
  constructor(
    public mouseEvent: MouseEvent,
    public pair: [InitialSize, InitialSize]) {
  }
}

type SidePair = [IPopulatedMultiPlotSide, IPopulatedMultiPlotSide];
