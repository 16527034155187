import { ChartSettings } from '../chart-settings';
import { Margin } from '../margin';

export abstract class MultiPlotViewerSettings extends ChartSettings {

  constructor(sourceCount: number) {
    super(sourceCount);

    this.svgPadding = new Margin(5, 5, 18, 5);
  }

  public get spaceBetweenPlots(): number {
    return 6;
  }
}
