import { SVGSelection } from '../../untyped-selection';
import { IMargin } from '../margin';
import { ISize } from '../size';
import { ILegendSettings } from '../legend-settings';

export const CONTAINER_CLASS = 'orbit-controls';

export interface IOrbitControlsInstructionsChartSettings {
  readonly svgSize: ISize;
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly legend: ILegendSettings;
}

export class OrbitControlsInstructionsRenderer {
  private readonly inner: OrbitControlsRendererInner;

  constructor(settings: IOrbitControlsInstructionsChartSettings) {
    this.inner = new OrbitControlsRendererInner(settings);
  }

  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }
}

export class OrbitControlsRendererInner {

  constructor(private readonly settings: IOrbitControlsInstructionsChartSettings) {
  }

  public render(selection: SVGSelection) {

    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + CONTAINER_CLASS).data([null]);
    let containerEnter = containerUpdate.enter().append('g').attr('class', CONTAINER_CLASS);
    let container = containerEnter.merge(containerUpdate);

    container.attr(
      'transform',
      `translate(${this.settings.svgPadding.left + this.settings.chartMargin.left}, ${this.settings.svgPadding.top + this.settings.chartMargin.top + this.settings.chartSize.height})`);

    let gUpdate = container.selectAll<SVGGElement, any>('.instruction').data([
      new ControlDefinition('Double Click', 'Reset'),
      new ControlDefinition('Right Button', 'Pan'),
      new ControlDefinition('Middle Button', 'Zoom'),
      new ControlDefinition('Ctrl + Wheel', 'Zoom'),
      new ControlDefinition('Left Button', 'Rotate'),
    ]);
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g').attr('class', 'instruction');
    let g = gEnter.merge(gUpdate);

    g.attr('transform', (v, i) => `translate(0, ${i * -15})`);

    gEnter.append('text');
    g.select('text')
      .text((v) => v.action + ': ' + v.effect);
  }
}

class ControlDefinition {
  constructor(
    public readonly action: string,
    public readonly effect: string) {
  }
}
