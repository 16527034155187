import * as d3 from '../../d3-bundle';
import { SVGSelection } from '../../untyped-selection';
import { ISize } from '../size';
import { IMargin } from '../margin';
import { IPosition, Position } from '../position';
import { ICanvasData } from '../canvas-utilities';
import { GetChannelColorDelegate } from '../chart-settings';
import { DomainNewsEvent, SecondaryDomainNewsEvent, SharedState } from '../shared-state';
import { SourceData } from '../data-pipeline/types/source-data';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedPlotSourceChannel } from '../data-pipeline/types/processed-plot-source-channel';
import { QuadTreeCursorDataPoint } from '../data-pipeline/types/quad-tree-cursor-data-point';
import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { MonotonicStatus } from '../channel-data-loaders/viewer-channel-data';
import { PlotClippingRenderer } from './plot-clipping-renderer';
import { Units } from '../../units';
import { GetInterpolatedChannelValueAtDomainValue } from '../channel-data-loaders/get-interpolated-channel-value-at-domain-value';
import { ColumnLegendList } from './multi-plot-viewer-base';
import { ProcessedDomainEvent } from './domain-event-handler';
import { CanvasDataRendererBase, DefaultRenderInformation } from '../components/canvas-data-renderer-base';
import { definedValues } from '../../defined-values';

interface IChartSettings {
  readonly uniqueId: string;
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly spaceBetweenPlots: number;
  readonly getChannelColor: GetChannelColorDelegate;
}

export abstract class MultiPlotDataRendererBase {

  constructor(private inner: MultiPlotDataRendererInnerBase) {
  }

  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  public domainEventProcessed(processedEvent: ProcessedDomainEvent): this {
    this.inner.domainEventProcessed(processedEvent);
    return this;
  }

  public primaryDomainName(value: string): this {
    this.inner.primaryDomainName = value;
    return this;
  }

  public layout(value: IPopulatedMultiPlotLayout): this {
    this.inner.layout = value;
    return this;
  }

  public sourceData(value: ReadonlyArray<SourceData>): this {
    this.inner.sourceData = value;
    return this;
  }

  public chartSettings(value: IChartSettings): this {
    this.inner.setSettings(value);
    return this;
  }

  public canvasData(value: ICanvasData): this {
    this.inner.setCanvasData(value);
    return this;
  }

  public xLegendList(value: ColumnLegendList): this {
    this.inner.xLegendList = value;
    return this;
  }

  public sharedState(value: SharedState): this {
    this.inner.sharedState = value;
    return this;
  }

  public domainSnapBehaviour(value: DomainSnapBehaviour): this {
    this.inner.domainSnapBehaviour = value;
    return this;
  }

  public getDomainSnapBehaviour(): DomainSnapBehaviour {
    return this.inner.domainSnapBehaviour;
  }

  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.inner.listeners.on(typenames, callback);
    return this;
  }

  public dispose(): this {
    this.inner.dispose();
    return this;
  }
}

export abstract class MultiPlotDataRendererInnerBase extends CanvasDataRendererBase<IChartSettings, DefaultRenderInformation> {
  public layout?: IPopulatedMultiPlotLayout;
  public sourceData?: ReadonlyArray<SourceData>;

  public primaryDomainName?: string;
  public xLegendList?: ColumnLegendList;
  public sharedState?: SharedState;
  public listeners = d3.dispatch('legendChanged');
  public domainSnapBehaviour = DomainSnapBehaviour.nearest;

  constructor(
    canvasCssPrefix: string,
    protected getInterpolatedChannelValueAtDomainValue: GetInterpolatedChannelValueAtDomainValue) {
    super('multi-plot-data-renderer', [canvasCssPrefix + '-viewer-canvas']);
  }

  public setSettings(value: IChartSettings) {
    this.settings = value;
  }

  public setCanvasData(value: ICanvasData) {
    this.canvasData = value;
  }

  public render(selection: SVGSelection) {
    if (!this.layout || !this.sourceData || !this.primaryDomainName) {
      return;
    }

    super.performRender(new DefaultRenderInformation());
  }

  public dispose() {
  }

  protected renderContext(targetContexts: ReadonlyArray<CanvasRenderingContext2D>, renderInformation: DefaultRenderInformation) {
    if (!this.settings || !this.canvasData || !this.layout || !this.layout.processed) {
      return;
    }

    let style = getComputedStyle(document.documentElement);

    let context: CanvasRenderingContext2D = renderInformation.canvases.first.canvasContext;

    let pixelRatio = this.canvasData.settings.pixelRatio;
    //let svgPadding = this.settings.svgPadding;
    //let chartMargin = this.settings.chartMargin;

    for (let plot of this.layout.processed.plots) {
      this.canvasUtilities.resetTransform(context, pixelRatio);

      let column = plot.column;
      let row = plot.row;

      let plotX = plot.absoluteRenderArea.x;
      let plotY = plot.absoluteRenderArea.y;

      let plotWidth = column.processed.plotSize;
      let plotHeight = row.processed.plotSize;

      context.translate(plotX, plotY);

      context.strokeStyle = `${style.getPropertyValue('--bs-border-color')}`;
      context.strokeRect(0.5, 0.5, plotWidth, plotHeight);

      context.save();
      try {
        context.beginPath();
        context.rect(0, 0, plotWidth, plotHeight);
        context.clip();

        this.drawZeroLines(context, plot);
        this.drawPlotFeatures(context, plot);

        for (let source of plot.sources) {
          for (let channel of source.channels) {
            this.drawChannelData(context, plot, channel);
          }
        }
      } finally {
        context.restore();
      }
    }

  }

  private drawZeroLines(context: CanvasRenderingContext2D, plot: ProcessedPlot) {
    if (!this.sourceData) {
      return;
    }

    let sourcesVisibility = this.sourceData.map(v => v.isVisible);
    let xMinimum = plot.column.processed.getVisibleMinimum(sourcesVisibility);
    let xMaximum = plot.column.processed.getVisibleMaximum(sourcesVisibility);
    let yMinimum = plot.row.processed.getVisibleMinimum(sourcesVisibility);
    let yMaximum = plot.row.processed.getVisibleMaximum(sourcesVisibility);

    if ((xMinimum < 0 && xMaximum > 0) || (yMinimum < 0 && yMaximum > 0)) {
      context.save();
      try {
        context.lineWidth = 1;
        context.strokeStyle = '#bbb';
        context.setLineDash([15, 5]);
        context.beginPath();

        let columnScale = plot.column.processed.scale;
        let rowScale = plot.row.processed.scale;

        if (yMinimum < 0 && yMaximum > 0) {
          let rowValue = rowScale(0) + 0.5;
          context.moveTo(
            columnScale(xMinimum),
            rowValue);
          context.lineTo(
            columnScale(xMaximum),
            rowValue);
        }

        if (xMinimum < 0 && xMaximum > 0) {
          let columnValue = columnScale(0) - 0.5;
          context.moveTo(
            columnValue,
            rowScale(yMinimum));
          context.lineTo(
            columnValue,
            rowScale(yMaximum));
        }

        context.stroke();
      } finally {
        context.restore();
      }
    }
  }

  protected abstract drawChannelData(context: CanvasRenderingContext2D, plot: ProcessedPlot, channel: ProcessedPlotSourceChannel): void;

  protected abstract drawPlotFeatures(context: CanvasRenderingContext2D, plot: ProcessedPlot): void;

  protected attachCanvasMouseMoveHandler(svg: SVGSelection): void {

    let isMouseDown: boolean = false;
    svg
      .on('mousedown.data-renderer-base', () => {
        isMouseDown = true;
      })
      .on('mouseup.data-renderer-base', () => {
        isMouseDown = false;
      })
      .on('mousemove.data-renderer-base', (mouseEvent: MouseEvent) => {
        let position = new Position(mouseEvent.offsetX, mouseEvent.offsetY);

        if (isMouseDown) {
          return;
        }

        this.handleMouseMoveEvent(position);

        this.listeners.call('legendChanged');
      })
      .on('mouseleave.data-renderer-base', () => {
        isMouseDown = false;

        this.handleMouseLeaveEvent();

        this.listeners.call('legendChanged');
      });
  }

  public domainEventProcessed(processedEvent: ProcessedDomainEvent) {
    this.resetInteractionData();
    this.renderMultiPlotCursorDataPoints(processedEvent.plotCursorDataPoints);
  }

  protected renderMultiPlotCursorDataPoints(items: ReadonlyArray<PlotCursorDataPoint>) {
    if (!this.container || !this.sourceData) {
      return;
    }

    const settings = this.definedSettings;
    const sourceData = this.sourceData;

    let pointUpdate = this.container.selectAll<SVGCircleElement, PlotCursorDataPoint>('.cursor-point').data(items as PlotCursorDataPoint[], (v: PlotCursorDataPoint) => v.id);
    pointUpdate.exit().remove();
    let pointEnter = pointUpdate.enter().append<SVGCircleElement>('circle')
      .attr('class', 'cursor-point')
      .attr('r', '2.5')
      .attr('stroke', 'black')
      .lower();

    let applyPositionAndColor = (selection: d3.Selection<SVGCircleElement, PlotCursorDataPoint, SVGGElement, null>, transitionDuration: number) => {
      ((transitionDuration ? selection.transition().duration(transitionDuration).ease(d3.easeLinear) : selection)
        .attr('clip-path', (d: PlotCursorDataPoint, i) => `url(#${PlotClippingRenderer.getPlotClipPathId(settings.uniqueId, d.plot.plotIndex)})`) as d3.Transition<SVGCircleElement, PlotCursorDataPoint, SVGGElement, null>)
        .attr('cx', (d: PlotCursorDataPoint) => d.point.x + d.plot.absoluteRenderArea.x)
        .attr('cy', (d: PlotCursorDataPoint) => d.point.y + d.plot.absoluteRenderArea.y)
        .attr('display', (d: PlotCursorDataPoint) => sourceData[d.point.sourceIndex].isVisible && d.point.channel.isVisible ? 'inherit' : 'none')
        .attr('fill', (d: PlotCursorDataPoint) => settings.getChannelColor(d.point.channel.channelIndex, d.point.sourceIndex));
    };

    applyPositionAndColor(pointEnter, 0);
    applyPositionAndColor(pointUpdate, 0 /*TRANSITION_DURATION*/);
  }

  protected drawCursorLine(position: IPosition | undefined) {
    if (!this.container) {
      return;
    }

    const settings = this.definedSettings;
    let lineUpdate = this.container.selectAll<SVGLineElement, IPosition>('.cursor-line').data(position ? [position] : []);
    lineUpdate.exit().remove();
    let lineEnter = lineUpdate.enter().append('line')
      .attr('class', 'cursor-line')
      .attr('stroke-width', 1);
    lineUpdate.merge(lineEnter)
      .attr('x1', (d: IPosition) => d.x + 0.5)
      .attr('y1', settings.svgPadding.top + settings.chartMargin.top)
      .attr('x2', (d: IPosition) => d.x + 0.5)
      .attr('y2', settings.svgPadding.top + settings.chartMargin.top + settings.chartSize.height - settings.spaceBetweenPlots);
  }

  protected drawClosestPointLines(positionOrUndefined: IPosition | undefined, plotOrUndefined: ProcessedPlot | undefined, points: (QuadTreeCursorDataPoint | undefined)[]) {
    if (!this.container) {
      return;
    }

    const settings = this.definedSettings;

    if (points.length) {
      if (!positionOrUndefined) {
        throw new Error('Plot must be defined when points are provided.');
      }
      if (!plotOrUndefined) {
        throw new Error('Position must be defined when points are provided.');
      }
    }

    const plot = plotOrUndefined;
    const position = positionOrUndefined;

    let linesUpdate = this.container.selectAll<SVGGElement, any>('.closest-point-lines').data([null]);
    let linesEnter = linesUpdate.enter().append('g').attr('class', 'closest-point-lines');

    let lines = linesEnter.merge(linesUpdate);

    let lineUpdate = lines.selectAll<SVGLineElement, QuadTreeCursorDataPoint>('.closest-point-line')
      .data(
        definedValues(points),
        (v: QuadTreeCursorDataPoint) => `point-line-${v.plotIndex}-${v.sourceIndex}`);

    lineUpdate.exit().remove();

    let lineEnter = lineUpdate.enter().append<SVGLineElement>('line')
      .attr('class', 'closest-point-line')
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .attr('x2', (d: QuadTreeCursorDataPoint) => d.x + plot.absoluteRenderArea.x)
      .attr('y2', (d: QuadTreeCursorDataPoint) => d.y + plot.absoluteRenderArea.y);

    lineUpdate.merge(lineEnter)
      .attr('clip-path', () => `url(#${PlotClippingRenderer.getPlotClipPathId(settings.uniqueId, plot.plotIndex)})`)
      .attr('x1', () => position.x)
      .attr('y1', () => position.y);

    lineUpdate
      //.transition().duration(TRANSITION_DURATION).ease(d3.easeLinear)
      .attr('x2', (d: QuadTreeCursorDataPoint) => d.x + plot.absoluteRenderArea.x)
      .attr('y2', (d: QuadTreeCursorDataPoint) => d.y + plot.absoluteRenderArea.y);
  }

  protected renderCursorDataPoints(plotOrUndefined: ProcessedPlot | undefined, points: CursorDataPoint[]) {
    if (points.length && !plotOrUndefined) {
      throw new Error('Plot must be defined when points are provided.');
    }

    const plot = plotOrUndefined;
    this.renderMultiPlotCursorDataPoints(points.map((v, i) => new PlotCursorDataPoint('point-' + i, plot, v)));
  }

  protected handleMouseLeaveEvent() {
  }

  protected resetInteractionData() {
    this.renderCursorDataPoints(undefined, []);
    this.drawCursorLine(undefined);
    this.drawClosestPointLines(undefined, undefined, []);
  }

  protected handleMouseMoveEvent(position: IPosition) {
    if (!this.layout || !this.svg) {
      return;
    }

    let plot = this.layout.processed.plots.find(v => v.isInPlot(position));
    if (!plot) {
      this.svg.style('cursor', 'inherit');
      return;
    }

    this.svg.style('cursor', 'crosshair');

    // If monotonically increasing active domain, render vertical line and interpolate.
    // Otherwise, use closest points.

    let activeDomainChannel = plot.column.processed.channels.find(v => v.isVisible && v.hasData);
    if (activeDomainChannel &&
      (activeDomainChannel.monotonicStatus === MonotonicStatus.Increasing
        || activeDomainChannel.monotonicStatus === MonotonicStatus.Decreasing)) {
      this.handleMouseMoveEventMonotonic(position, plot, activeDomainChannel);
      this.drawClosestPointLines(undefined, undefined, []);
      this.drawCursorLine(position);
    } else {
      let closestPoints = this.handleMouseMoveEventNonMonotonic(position, plot);
      this.drawCursorLine(undefined);
      this.drawClosestPointLines(position, plot, closestPoints);
    }
  }

  protected handleMouseMoveEventMonotonic(position: IPosition, plot: ProcessedPlot, activeDomainChannel: ProcessedMultiPlotChannel) {
    if (!this.layout || !this.sharedState) {
      return;
    }

    let activeMonotonicStatus = activeDomainChannel.monotonicStatus;
    let xChannels: ProcessedMultiPlotChannel[] = [...plot.column.processed.channels];
    if (xChannels.every(v => v.name !== this.primaryDomainName)) {
      // Always raise the event on the primary domain.
      xChannels.push(this.layout.processed.primaryDomain);
    }

    let secondaryDomain = this.layout.processed.secondaryDomain;

    let plotPosition = new Position(position.x - plot.absoluteRenderArea.x, position.y - plot.absoluteRenderArea.y);
    let activeDomainValue = plot.column.processed.scale.invert(plotPosition.x);

    for (let channel of xChannels) {
      let domainNews = this.sharedState.getDomainNews(channel.name);
      let domainNewsEvent = this.getDomainNewsEventForChannel(channel, activeDomainValue, activeDomainChannel, activeMonotonicStatus);

      if (secondaryDomain && channel.name === this.primaryDomainName) {
        let secondaryDomainNewsEvent = this.getDomainNewsEventForChannel(secondaryDomain, activeDomainValue, activeDomainChannel, activeMonotonicStatus);
        domainNewsEvent = new DomainNewsEvent(
          domainNewsEvent.sourceValues,
          new SecondaryDomainNewsEvent(secondaryDomain.name, secondaryDomainNewsEvent));
      }

      domainNews.raise(domainNewsEvent);
    }
  }

  private getDomainNewsEventForChannel(channel: ProcessedMultiPlotChannel, activeDomainValue: number, activeDomainChannel: ProcessedMultiPlotChannel, activeMonotonicStatus: MonotonicStatus): DomainNewsEvent {
    if (channel.isVisible) {
      return new DomainNewsEvent(channel.sources.map(
        v => Units.convertValueToSi(activeDomainValue, channel.units)));
    }

    let requiredDomainValues: number[] = [];
    for (let sourceIndex = 0; sourceIndex < channel.sources.length; ++sourceIndex) {
      let requiredDomainData = channel.sources[sourceIndex].data;
      let activeDomainData = activeDomainChannel.sources[sourceIndex].data;
      if (requiredDomainData && activeDomainData) {
        let requiredDomainValue = this.getInterpolatedChannelValueAtDomainValue.execute(requiredDomainData, activeDomainValue, activeDomainData, activeMonotonicStatus);
        requiredDomainValue = Units.convertValueToSi(requiredDomainValue, channel.units);
        requiredDomainValues.push(requiredDomainValue);
      } else {
        requiredDomainValues.push(NaN);
      }
    }
    return new DomainNewsEvent(requiredDomainValues);
  }

  protected handleMouseMoveEventNonMonotonic(position: IPosition, plot: ProcessedPlot): (QuadTreeCursorDataPoint | undefined)[] {

    let closestPoints = plot.getClosestPointsForSources(position);

    if (this.layout && this.sharedState && this.sourceData) {
      let primaryDomain = this.layout.processed.primaryDomain;
      let domainNews = this.sharedState.getDomainNews(primaryDomain.name);
      domainNews.raise(new DomainNewsEvent(this.sourceData.map((source, sourceIndex) => {
        let p = closestPoints.find(v => !!v && v.sourceIndex === sourceIndex);
        if (p) {
          let channel = primaryDomain.sources[p.sourceIndex];
          if (channel.data) {
            return Units.convertValueToSi(channel.data[p.index], channel.units);
          }
        }

        return NaN;
      })));
    }

    return closestPoints;
  }
}

export class PlotCursorDataPoint {
  constructor(
    public id: string,
    public plot: ProcessedPlot,
    public point: CursorDataPoint) {
  }
}

export class CursorDataPoint {
  constructor(
    public x: number,
    public y: number,
    public channel: ProcessedMultiPlotChannel,
    public sourceIndex: number) {
  }
}

export class DomainPosition {
  constructor(
    public readonly nearestIndex: number,
    public readonly previousIndex: number,
    public readonly ratio: number) {
  }
}

export enum DomainSnapBehaviour {
  nearest,
  linearInterpolation
}
