import {DomainNewsEvent, SharedState} from '../shared-state';
import {Subscription} from 'rxjs';
import {
  CursorDataPoint, DomainPosition, DomainSnapBehaviour,
  PlotCursorDataPoint
} from './multi-plot-data-renderer-base';
import {INDEX_DOMAIN_NAME, SLAPCENTRELINE_DOMAIN_NAME} from '../../constants';
import {Units} from '../../units';
import {SearchDirection, Utilities} from '../../utilities';
import { SourceData } from '../data-pipeline/types/source-data';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import {ColumnLegendList} from './multi-plot-viewer-base';
import * as d3 from '../../d3-bundle';

export const LEGEND_CHANGED_EVENT = 'legendChanged';
export const DOMAIN_EVENT_PROCESSED_EVENT = 'domainEventProcessed';

export class DomainEventHandler{
  private readonly subscriptions: Subscription = new Subscription();

  private lastDomainEvents: { [domainName: string]: DomainNewsEvent } = {};

  private layout?: IPopulatedMultiPlotLayout;
  private sourceData?: ReadonlyArray<SourceData>;
  private xLegendList?: ColumnLegendList;
  private domainSnapBehaviour?: DomainSnapBehaviour;

  public readonly listeners = d3.dispatch(LEGEND_CHANGED_EVENT, DOMAIN_EVENT_PROCESSED_EVENT);

  constructor(
    private readonly sharedState: SharedState,
    private readonly primaryDomainName?: string){

  }

  public dispose(){
    this.subscriptions.unsubscribe();
  }

  private addSubscription(domainName: string) {
    this.subscriptions.add(this.sharedState.getDomainNews(domainName)
      .observable.subscribe(event => this.handleDomainEvent(event, domainName)));
  }

  public build(){
    if(this.sharedState){
      if(this.primaryDomainName){
        this.addSubscription(this.primaryDomainName);
      }
    }
  }

  public setLayout(layout: IPopulatedMultiPlotLayout): this {
    this.layout = layout;
    return this;
  }

  public setSourceData(sourceData: ReadonlyArray<SourceData>): this {
    this.sourceData = sourceData;
    return this;
  }

  public setXLegendList(xLegendList: ColumnLegendList): this {
    this.xLegendList = xLegendList;
    return this;
  }

  public setDomainSnapBehaviour(domainSnapBehaviour: DomainSnapBehaviour): this{
    this.domainSnapBehaviour = domainSnapBehaviour;
    return this;
  }

  public render(){
    if(this.primaryDomainName && this.lastDomainEvents[this.primaryDomainName]){
      this.handleDomainEvent(this.lastDomainEvents[this.primaryDomainName], this.primaryDomainName);
    }
  }

  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.listeners.on(typenames, callback);
    return this;
  }

  protected handleDomainEvent(event: DomainNewsEvent, domainName: string){
    this.lastDomainEvents[domainName] = event;

    if(!this.xLegendList || !this.sourceData) {
      return;
    }

    //for(let plot of this.cursorData.plots) {
    //  plot.resetLegendValues();
    //}

    this.xLegendList.clearChannels();

    let plotCursorDataPoints: PlotCursorDataPoint[] = [];
    for(let sourceIndex=0; sourceIndex < this.sourceData.length; ++sourceIndex){

      let domainPosition: DomainPosition | undefined;
      let eventSourceValue = event.getSourceValue(sourceIndex);

      // We do this below now (see comment below).
      // if(isNaN(eventSourceValue) && event.secondary){
      //   domainName = event.secondary.domainName;
      //   eventSourceValue = event.secondary.event.getSourceValue(sourceIndex);
      // }

      if(domainName === INDEX_DOMAIN_NAME){
        let nextIndex = Math.ceil(eventSourceValue);

        let nearestIndex = nextIndex;
        let previousIndex = nextIndex;
        let ratio = 1;

        if(nextIndex > 0){
          previousIndex = nextIndex - 1;

          let difference = nextIndex - eventSourceValue;
          let previousDifference = eventSourceValue - (nextIndex - 1);
          if(previousDifference < difference){
            nearestIndex = nextIndex - 1;
          }

          ratio = difference / (difference + previousDifference);
        }

        domainPosition = new DomainPosition(nearestIndex, previousIndex, ratio);
      } else {
        let domainValue = this.sourceData[sourceIndex].channels.get(domainName);

        // We do this slightly uglier solution rather than the commented out code above, because
        // we must always send sRun from the track to support QSL which doesn't have an sRunCentreLine
        // channel. Previously we would send a NaN sRun if the track had a centre line, but this broke QSL.
        if(event.secondary && event.secondary.domainName === SLAPCENTRELINE_DOMAIN_NAME){
          let secondaryDomainValue = this.sourceData[sourceIndex].channels.get(event.secondary.domainName);
          if(secondaryDomainValue.hasData) {
            domainValue = secondaryDomainValue;
            domainName = event.secondary.domainName;
            eventSourceValue = event.secondary.event.getSourceValue(sourceIndex);
          }
        }

        if(domainValue && domainValue.data && domainValue.data.length) {
          eventSourceValue = Units.convertValueFromSi(eventSourceValue, domainValue.units);

          let nextIndex = Utilities.findIndexInMonotonicallyIncreasingData(domainValue.data, eventSourceValue, SearchDirection.Forwards);
          if(nextIndex === -1){
            nextIndex = domainValue.data[domainValue.data.length - 1];
          }

          let nearestIndex = nextIndex;
          let previousIndex = nextIndex;
          let ratio = 1;

          if(nextIndex > 0){
            previousIndex = nextIndex - 1;

            let difference = domainValue.data[nextIndex] - eventSourceValue;
            let previousDifference = eventSourceValue - domainValue.data[previousIndex];
            if(previousDifference < difference){
              nearestIndex = nextIndex - 1;
            }

            ratio = difference / (difference + previousDifference);
          }

          domainPosition = new DomainPosition(nearestIndex, previousIndex, ratio);
        }
      }

      if(!domainPosition) {
        continue;
      }

      this.handleDomainEventForSource(sourceIndex, domainPosition, plotCursorDataPoints);
    }

    this.listeners.call(LEGEND_CHANGED_EVENT);
    this.listeners.call(DOMAIN_EVENT_PROCESSED_EVENT, undefined, new ProcessedDomainEvent(event, plotCursorDataPoints));
  }

  protected handleDomainEventForSource(sourceIndex: number, domainPosition: DomainPosition, plotCursorDataPoints: PlotCursorDataPoint[]) {
    if(!this.xLegendList || !this.layout || this.sharedState.domainOverridden){
      return;
    }

    let id = 0;
    for (let plot of this.layout.processed.plots) {

      for (let columnChannel of plot.column.processed.channels) {

        if (!this.xLegendList.channels.find(v => v.name === columnChannel.name)) {
          //if(columnChannel.isVisible){
          this.xLegendList.addChannel(columnChannel);
          //}
        }

        let columnChannelSource = columnChannel.sources[sourceIndex];

        if (!columnChannelSource || !columnChannelSource.data) {
          continue;
        }

        let getValueDelegate: (data: ReadonlyArray<number>, position: DomainPosition) => number;
        if(this.domainSnapBehaviour === DomainSnapBehaviour.linearInterpolation){
          getValueDelegate = (data: ReadonlyArray<number>, position: DomainPosition) => (data[position.previousIndex] * position.ratio) + (data[position.previousIndex + 1] * (1 - position.ratio));
        } else{
          getValueDelegate = (data: ReadonlyArray<number>, position: DomainPosition) => data[domainPosition.nearestIndex];
        }

        let xValue = getValueDelegate(columnChannelSource.data, domainPosition);

        columnChannel.legendValues[sourceIndex] = xValue;

        for (let rowChannel of plot.row.processed.channels) {

          let rowChannelSource = rowChannel.sources[sourceIndex];

          if (!rowChannelSource || !rowChannelSource.data) {
            continue;
          }

          let yValue = getValueDelegate(rowChannelSource.data, domainPosition);

          rowChannel.legendValues[sourceIndex] = yValue;

          if(!plot.hasChannelPair(columnChannel.name, rowChannel.name)){
            continue;
          }

          let cursorDataPoint = new CursorDataPoint(plot.column.processed.scale(xValue), plot.row.processed.scale(yValue), rowChannel, sourceIndex);

          if (!isNaN(cursorDataPoint.x) && !isNaN(cursorDataPoint.y)) {
            if(columnChannel.isVisible && rowChannel.isVisible){
              plotCursorDataPoints.push(new PlotCursorDataPoint('point-' + sourceIndex + '-' + (id++), plot, cursorDataPoint));
            }
          }
        }
      }
    }
  }
}

export class ProcessedDomainEvent{
  constructor(
    public readonly event: DomainNewsEvent,
    public readonly plotCursorDataPoints: ReadonlyArray<PlotCursorDataPoint>){
  }
}
