import { SVGSelection } from '../../untyped-selection';
import { CanvasUtilities, ICanvasData } from '../canvas-utilities';
import * as d3 from '../../d3-bundle';
import { BaseType } from 'd3-selection';
import { DisplayableError } from '../../displayable-error';

export abstract class CanvasDataRendererBase<TChartSettings, TRenderInformation extends RenderInformation> {
  protected canvasUtilities = new CanvasUtilities();

  protected svg?: SVGSelection;
  protected container?: d3.Selection<SVGGElement, null, SVGElement, unknown>;

  protected constructor(
    private readonly containerCssPrefix: string,
    private readonly canvasCssClasses: ReadonlyArray<string>,
    protected settings?: TChartSettings,
    protected canvasData?: ICanvasData) {
  }

  protected get definedSettings(): TChartSettings {
    if (!this.settings) {
      throw new Error('Settings are not defined.');
    }

    return this.settings;
  }

  public performRender(renderInformation: TRenderInformation) {
    if (!this.settings || !this.canvasData || !this.canvasCssClasses) {
      return;
    }

    this.svg = this.canvasData.parent.select<SVGElement>('svg');

    let containerClassName = this.canvasCssClasses.join('-') + '-data-renderer';
    let containerUpdate = this.svg.selectAll<SVGGElement, unknown>('.' + containerClassName).data([null]);
    let containerEnter = containerUpdate.enter()
      .append<SVGGElement>('g').attr('class', containerClassName + ' ' + this.containerCssPrefix);
    this.container = containerUpdate.merge(containerEnter);

    for (let canvasCssClass of this.canvasCssClasses) {
      let [canvasUpdate, canvasEnter, canvasAll] = this.canvasUtilities.getCanvas(this.canvasData, canvasCssClass);

      let canvas = canvasAll.node();
      if (!canvas) {
        throw new DisplayableError('Unable to get canvas.');
      }

      let context = canvas.getContext('2d');
      if (!context) {
        throw new DisplayableError('Unable to write to canvas.');
      }
      renderInformation.canvases.add(new CanvasInformation(
        canvasCssClass,
        canvasUpdate,
        canvasEnter,
        canvasAll,
        context));
    }

    let isFirstCall = !containerEnter.empty();
    if (isFirstCall) {
      this.attachCanvasMouseMoveHandler(this.svg);
    }

    this.canvasUtilities.updateSvgSize(this.canvasData.settings);

    let targetContexts = renderInformation.getTargetCanvasContexts(isFirstCall);

    targetContexts.forEach(c => c.save());
    try {
      let pixelRatio = this.canvasData.settings.pixelRatio;
      targetContexts.forEach(c => c.scale(pixelRatio, pixelRatio));

      let svgSize = this.canvasData.settings.svgSize;
      targetContexts.forEach(c => c.clearRect(0, 0, svgSize.width, svgSize.height));

      this.renderContext(targetContexts, renderInformation);
    } finally {
      targetContexts.forEach(c => c.restore());
    }
  }

  protected abstract attachCanvasMouseMoveHandler(svg: SVGSelection): void;

  protected abstract renderContext(targetContexts: ReadonlyArray<CanvasRenderingContext2D>, renderInformation: TRenderInformation): void;
}

export abstract class RenderInformation {
  public readonly canvases: Canvases = new Canvases();
  public abstract getTargetCanvasContexts(isFirstCall: boolean): ReadonlyArray<CanvasRenderingContext2D>;
}

export class DefaultRenderInformation extends RenderInformation {
  public readonly canvases: Canvases = new Canvases();

  public getTargetCanvasContexts(isFirstCall: boolean): ReadonlyArray<CanvasRenderingContext2D> {
    return this.canvases.all.map(v => v.canvasContext);
  }
}

export class Canvases {
  private readonly list: CanvasInformation[] = [];
  private readonly map: { [cssPrefix: string]: CanvasInformation } = {};

  public add(item: CanvasInformation) {
    this.list.push(item);
    this.map[item.cssClass] = item;
  }

  public get count(): number {
    return this.list.length;
  }

  public get all(): ReadonlyArray<CanvasInformation> {
    return [...this.list];
  }

  public getByIndex(index: number): CanvasInformation {
    return this.list[index];
  }

  public getByCssClass(cssPrefix: string): CanvasInformation {
    return this.map[cssPrefix];
  }

  public get first(): CanvasInformation {
    return this.list[0];
  }
}

export class CanvasInformation {
  constructor(
    public readonly cssClass: string,
    public readonly canvasUpdate: d3.Selection<HTMLCanvasElement, any, BaseType, any>,
    public readonly canvasEnter: d3.Selection<HTMLCanvasElement, any, BaseType, any>,
    public readonly canvas: d3.Selection<HTMLCanvasElement, any, BaseType, any>,
    public readonly canvasContext: CanvasRenderingContext2D) {
  }
}
