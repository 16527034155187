import * as d3xy from './xyzoom';
import * as d3 from '../../d3-bundle';
import { SVGSelection } from '../../untyped-selection';
import { IMargin } from '../margin';
import { ISize } from '../size';
import { IPosition, Position } from '../position';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { ProcessedMultiPlotChannel } from '../data-pipeline/types/processed-multi-plot-channel';
import { SharedState } from '../shared-state';
import { PlotClippingRenderer } from './plot-clipping-renderer';
import { DomainSnapBehaviour } from './multi-plot-data-renderer-base';
import { GetChannelCalculationResult } from './get-channel-calculation-result';
import { Subscription } from 'rxjs';
import { ColumnLegendList } from './multi-plot-viewer-base';
import { ToggleButton, ToggleOptionsRenderer, OPTIONS_LEFT_PADDING, OPTIONS_BOTTOM_PADDING } from '../components/toggle-options-renderer';
import { ILegendChannel } from '../components/legend-renderer';
import { ZoomBrushSelection } from './zoom-brush-selection';

interface IChartSettings {
  readonly uniqueId: string;
  readonly svgSize: ISize;
  readonly svgPadding: IMargin;
  readonly chartMargin: IMargin;
  readonly chartSize: ISize;
  readonly spaceBetweenPlots: number;
}

export enum ZoomType {
  x,
  y,
  xy
}

export enum CalculationType {
  none,
  mean,
  min,
  max,
  delta,
  range,
  gradient,
  integral
}

export class ZoomRenderer {
  private inner = new ZoomRendererInner();

  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  public preRender(selection: SVGSelection): this {
    this.inner.preRender(selection);
    return this;
  }

  public layout(value: IPopulatedMultiPlotLayout): this {
    this.inner.layout = value;
    return this;
  }

  public chartSettings(value: IChartSettings): this {
    this.inner.settings = value;
    return this;
  }

  public zoomType(value: ZoomType): this {
    this.inner.zoomType = value;
    return this;
  }

  public sharedState(value: SharedState): this {
    this.inner.sharedState = value;
    return this;
  }

  public xLegendList(value: ColumnLegendList): this {
    this.inner.xLegendList = value;
    return this;
  }

  public domainSnapBehaviour(value: DomainSnapBehaviour): this {
    this.inner.domainSnapBehaviour = value;
    return this;
  }

  public get isRenderingCalculations(): boolean {
    return this.inner.isRenderingCalculations;
  }

  public on(typenames: string, callback: (this: object, ...args: any[]) => void): this {
    this.inner.listeners.on(typenames, callback);
    return this;
  }

  public dispose(): this {
    this.inner.dispose();
    return this;
  }
}

// Because methods like are called after a new set of processed plots are created, but before
// they are bound to the UI, we need to look up the latest plot from the layout.
function getCurrentPlot(layout: IPopulatedMultiPlotLayout, plot: ProcessedPlot): ProcessedPlot {
  return layout.processed.plots[plot.plotIndex];
}

const ZOOM_PROPERTY = '__cs_zoom';

export class ZoomRendererInner {
  public layout?: IPopulatedMultiPlotLayout;
  public settings?: IChartSettings;
  public zoomType: ZoomType = ZoomType.xy;
  public sharedState?: SharedState;
  public xLegendList?: ColumnLegendList;
  public domainSnapBehaviour = DomainSnapBehaviour.nearest;

  public _calculationType: CalculationType = CalculationType.none;
  public get calculationType(): CalculationType {
    return this._calculationType;
  }
  public set calculationType(value: CalculationType){
    this._calculationType = value;
    if(this.sharedState){
      this.sharedState.domainOverridden = this.isRenderingCalculations;
    }
  }

  public listeners = d3.dispatch('zoom', 'legendChanged');

  private subscriptions?: Subscription;

  private enableZoomByScroll: boolean = false;
  public _zoomProgress: ZoomProgress | undefined;
  public get zoomProgress(): ZoomProgress | undefined {
    return this._zoomProgress;
  }
  public set zoomProgress(value: ZoomProgress | undefined){
    this._zoomProgress = value;
    if(this.sharedState){
      this.sharedState.domainOverridden = this.isRenderingCalculations;
    }
  }
  private isZooming: boolean = false;

  private toggleOptionsRenderer = new ToggleOptionsRenderer();
  private zoomToggles: ToggleButton<ZoomType>[];
  private calculationToggles: ToggleButton<CalculationType>[];

  private getChannelCalculationResult: GetChannelCalculationResult = GetChannelCalculationResult.create();

  constructor() {
    this.zoomToggles = [
      new ToggleButton<ZoomType>(ZoomType.x, 'x', (t) => this.zoomType = t.value, 15),
      new ToggleButton<ZoomType>(ZoomType.y, 'y', (t) => this.zoomType = t.value, 15),
      new ToggleButton<ZoomType>(ZoomType.xy, 'xy', (t) => this.zoomType = t.value, 15),
    ];

    let setCalculationTypeDelegate = (t: ToggleButton<CalculationType>) => {
      this.calculationType = t.value;

      if (!this.isRenderingCalculations) {
        this.performChannelCalculations();
        this.listeners.call('legendChanged');
      }
    };
    let getSelectedClassDelegate = (t: ToggleButton<CalculationType>) => this.isRenderingCalculations ? 'calculating-toggle-selected' : null;
    this.calculationToggles = [
      new ToggleButton<CalculationType>(CalculationType.none, 'none', (t) => {
        this.calculationType = t.value;
        this.resetLegendValues();
        this.listeners.call('legendChanged');
      }, 30),
      new ToggleButton<CalculationType>(CalculationType.mean, 'mean', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.min, 'min', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.max, 'max', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.delta, 'delta', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.range, 'range', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.gradient, 'grad', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
      new ToggleButton<CalculationType>(CalculationType.integral, 'integ', setCalculationTypeDelegate, 30, getSelectedClassDelegate),
    ];
  }

  public get isRenderingCalculations(): boolean {
    return !!(this.zoomProgress && this.calculationType !== CalculationType.none);
  }

  public dispose() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  public preRender(selection: SVGSelection) {
    if (!this.layout || !this.settings || !this.sharedState) {
      return;
    }

    this.zoomProgress = undefined;

    let adjustedScales = new Set<d3.ScaleLinear<number, number>>();

    //let layout = this.layout;
    let zoomHandlerUpdate = selection.selectAll<SVGElement, ProcessedPlot>('.zoom-handler');
    zoomHandlerUpdate.data(this.layout.processed.plots as ProcessedPlot[])
      .each(function(plot: ProcessedPlot) {
        //plot = getCurrentPlot(layout, plot);
        let transform = d3xy.xyzoomTransform(this);

        if (transform) {
          let columnScale = plot.column.processed.scale;
          if (!adjustedScales.has(columnScale)) {
            columnScale.domain(transform.rescaleX(columnScale).domain());
            adjustedScales.add(columnScale);
          }

          let rowScale = plot.row.processed.scale;
          if (!adjustedScales.has(rowScale)) {
            rowScale.domain(transform.rescaleY(rowScale).domain());
            adjustedScales.add(rowScale);
          }
        }
      });
  }

  public render(selection: SVGSelection) {
    if (!this.layout || !this.settings || !this.sharedState) {
      return;
    }

    let containerClassName = 'zoom-renderer';

    let containerUpdate = selection.selectAll<SVGGElement, null>('.' + containerClassName).data([null]);
    let containerEnter = containerUpdate.enter().append<SVGGElement>('g').attr('class', containerClassName + ' multi-plot-zoom-renderer');
    let container = containerEnter.merge(containerUpdate);

    if (!this.subscriptions) {
      this.subscriptions = this.sharedState.keyPressNews.subscribe((key) => {
        let requiresRender: boolean = false;
        if (key === 'x') {
          this.zoomType = ZoomType.x;
          this.enableZoomByScroll = true;
          requiresRender = true;
        } else if (key === 'y') {
          this.zoomType = ZoomType.y;
          this.enableZoomByScroll = true;
          requiresRender = true;
        } else if (key === 'z') {
          this.zoomType = ZoomType.xy;
          this.enableZoomByScroll = true;
          requiresRender = true;
        } else {
          this.enableZoomByScroll = false;
        }

        if (key === 'm' || key === 'n') {
          let currentToggle = this.calculationToggles.find(v => v.value === this.calculationType);
          let nextIndex = 0;
          if (currentToggle) {
            let currentIndex = this.calculationToggles.indexOf(currentToggle);
            if(key === 'm'){
              nextIndex = currentIndex + 1;
              if (nextIndex === this.calculationToggles.length) {
                nextIndex = 0;
              }
            }
            if(key ==='n'){
              nextIndex = currentIndex - 1;
              if (nextIndex === -1) {
                nextIndex = this.calculationToggles.length - 1;
              }
            }
          }
          this.calculationToggles[nextIndex].clickAction(this.calculationToggles[nextIndex]);
          requiresRender = true;
        }

        if (key === 'Escape') {
          if (this.zoomProgress) {
            this.zoomProgress = undefined;
            requiresRender = true;
          }
        }

        if (requiresRender) {
          this.renderZoomOptions(selection, container);
          this.renderZoomBrushes(selection, container);
        }
      });
    }

    this.renderZoomHandlers(selection, container);
    this.renderZoomOptions(selection, container);
    this.renderZoomBrushes(selection, container);
  }

  private renderZoomHandlers(selection: SVGSelection, container: d3.Selection<SVGGElement, null, SVGElement, any>) {
    if (!this.layout) {
      return;
    }

    let self = this;

    let zoomHandlerUpdate = container.selectAll<SVGRectElement, ProcessedPlot>('.zoom-handler').data(this.layout.processed.plots as ProcessedPlot[]);
    zoomHandlerUpdate.exit().remove();
    let zoomHandlerEnter = zoomHandlerUpdate.enter()
      .append<SVGRectElement>('rect')
      .attr('class', 'zoom-handler')
      .attr('fill', 'transparent');

    let zoomHandler = zoomHandlerUpdate.merge(zoomHandlerEnter);

    zoomHandlerEnter

      .on('dblclick', () => {
        self=this;
        zoomHandler.each(function(d: ProcessedPlot) {
          let zoom: any = (this as any)[ZOOM_PROPERTY];
          d3.select(this).call(zoom.transform, d3xy.xyzoomIdentity);
        });
      });

    zoomHandler
      .attr('transform',
        (d: ProcessedPlot) =>
          'translate('
          + (d.absoluteRenderArea.x)
          + ','
          + (d.absoluteRenderArea.y)
          + ')')
      .attr('width', (d: ProcessedPlot) => d.absoluteRenderArea.width)
      .attr('height', (d: ProcessedPlot) => d.absoluteRenderArea.height);

    let zoomFilter = this.getZoomFilter();
    let scaleRatio = this.getScaleRatio();

    self=this;
    zoomHandlerEnter.each(function(d: ProcessedPlot) {
      let zoom: typeof d3xy.xyzoom = (this as any)[ZOOM_PROPERTY]
      = (d3xy.xyzoom() as any)
      .extent([[0, 0], [d.column.processed.plotSize, d.row.processed.plotSize]])
      .scaleExtent([1, 1000], [1, 1000])
      .filter(zoomFilter)
      .on('zoom', (currentEvent: any) => self.zoomHandler(selection, d, currentEvent));

      d3.select<SVGRectElement, ProcessedPlot>(this)
      .call(zoom)
      .on('wheel', (currentEvent) => {
        // https://github.com/d3/d3-zoom#zoom_scaleExtent
        if (self.enableZoomByScroll) {
          currentEvent.preventDefault();
        }
      })
      .on('contextmenu.zoom-brush', (currentEvent: any, d: ProcessedPlot) => {
        let mouseEvent = <MouseEvent>currentEvent;
        mouseEvent.preventDefault(); // Prevent browser menu.

        if (self.zoomProgress) {
          self.zoomProgress = undefined;
        } else {
          let mousePosition = new Position(mouseEvent.offsetX, mouseEvent.offsetY);
          let start = new Position(mousePosition.x - d.absoluteRenderArea.x, mousePosition.y - d.absoluteRenderArea.y);
          self.zoomProgress = new ZoomProgress(d, start);
        }
        self.renderZoomBrushes(selection, container);
        self.renderZoomOptions(selection, container);
      })
      .on('click.zoom-brush', () => {
        if (self.zoomProgress) {
          self.zoomToZoomProgress(selection);
        }
      })
      .on('mousemove.zoom-brush', (currentEvent: any, d: ProcessedPlot) => {
        if (self.zoomProgress) {
          let mouseEvent = <MouseEvent>currentEvent;
          let mousePosition = new Position(mouseEvent.offsetX, mouseEvent.offsetY);
          let zoomPlot = self.zoomProgress.plot;

          let x: number;
          if (zoomPlot.column === d.column) {
            x = mousePosition.x - d.absoluteRenderArea.x;
          } else {
            x = d.column.processed.offset > zoomPlot.column.processed.offset
              ? zoomPlot.absoluteRenderArea.width
              : 0;
          }

          let y: number;
          if (zoomPlot.row === d.row) {
            y = mousePosition.y - d.absoluteRenderArea.y;
          } else {
            y = d.row.processed.offset > zoomPlot.row.processed.offset
              ? zoomPlot.absoluteRenderArea.height
              : 0;
          }

          self.zoomProgress.end = new Position(x, y);

          self.renderZoomBrushes(selection, container);
        }
      });
    });

    zoomHandler.each(function(d: ProcessedPlot) {
      let zoom: any = (this as any)[ZOOM_PROPERTY];
      zoom
      .extent([[0, 0], [d.column.processed.plotSize, d.row.processed.plotSize]])
        .translateExtent([[0, 0], [d.column.processed.plotSize, d.row.processed.plotSize]])
        .scaleRatio(scaleRatio);
    });
  }

  private renderZoomBrushes(selection: SVGSelection, container: d3.Selection<SVGGElement, null, SVGElement, any>) {
    if (!this.layout || !this.settings) {
      return;
    }

    const settings = this.settings;
    const self = this;

    let zoomBrushData: ZoomBrushPlot[] = [];
    if (this.zoomProgress) {
      const zoomProgress = this.zoomProgress;
      zoomBrushData.push(new ZoomBrushPlot(zoomProgress.plot, true));
      if (this.zoomType === ZoomType.x || this.zoomType === ZoomType.xy) {
        /*let isMonotonicXDomain = false;
        let visibleChannels = zoomProgress.plot.column.processed.channels.filter(v => v.isVisible);
        if(visibleChannels.length){
          isMonotonicXDomain = visibleChannels[0].isMonotonic;
        }
*/
        zoomBrushData.push(...this.layout.processed.plots.filter(
          v => v !== zoomProgress.plot && v.column === zoomProgress.plot.column)
          .map(v => new ZoomBrushPlot(v, true/*isMonotonicXDomain && this.zoomType === ZoomType.x*/)));
      }
      if (this.zoomType === ZoomType.y || this.zoomType === ZoomType.xy) {
        zoomBrushData.push(...this.layout.processed.plots.filter(
          v => v !== zoomProgress.plot && v.row === zoomProgress.plot.row)
          .map(v => new ZoomBrushPlot(v, false)));
      }
    }

    if (this.isRenderingCalculations) {
      this.resetLegendValues();
    }

    let zoomBrushUpdate = container.selectAll<SVGRectElement, ZoomBrushPlot>('.zoom-brush').data(zoomBrushData);
    zoomBrushUpdate.exit().remove();
    let zoomBrushEnter = zoomBrushUpdate.enter()
      .append('rect')
      .attr('class', 'zoom-brush');
    let zoomBrush = zoomBrushUpdate.merge(zoomBrushEnter);

    zoomBrush.each(function(d: ZoomBrushPlot) {
      let brush = d3.select(this);
      let brushArea = self.getZoomProgressBrushArea(d.plot);

      if (brushArea) {
        brush
          .attr('clip-path', `url(#${PlotClippingRenderer.getPlotClipPathId(settings.uniqueId, d.plot.plotIndex)})`)
          .attr('x', brushArea.x + d.plot.absoluteRenderArea.x)
          .attr('y', brushArea.y + d.plot.absoluteRenderArea.y)
          .attr('width', brushArea.width)
          .attr('height', brushArea.height)
          .classed('calculating-zoom-brush', () => d.includeInCalculations && self.isRenderingCalculations);

        if (self.isRenderingCalculations) {
          self.performZoomBrushCalculations(d, brushArea);
        }
      } else {
        brush.attr('opacity', 0);
      }
    });

    if (this.isRenderingCalculations) {
      this.listeners.call('legendChanged');
    }
  }

  private resetLegendValues() {
    if (!this.layout || !this.xLegendList) {
      return;
    }

    for (let xLegendItem of this.xLegendList.channels) {
      for (let i = 0; i < xLegendItem.legendValues.length; ++i) {
        xLegendItem.legendValues[i] = NaN;
      }
    }
    for (let row of this.layout.processed.rows) {
      for (let channel of row.processed.channels) {
        for (let i = 0; i < channel.legendValues.length; ++i) {
          channel.legendValues[i] = NaN;
        }
      }
    }
  }

  private getZoomProgressBrushArea(d: ProcessedPlot): ZoomBrushSelection | undefined {
    let brushArea: ZoomBrushSelection | undefined;
    if (this.zoomProgress) {
      if (this.zoomType === ZoomType.x
        || (this.zoomType === ZoomType.xy && d !== this.zoomProgress.plot && d.column === this.zoomProgress.plot.column)) {
        brushArea = new ZoomBrushSelection(
          this.zoomProgress.start.x,
          -1,
          this.zoomProgress.end.x - this.zoomProgress.start.x,
          d.absoluteRenderArea.height + 2);
      }
      if (this.zoomType === ZoomType.y
        || (this.zoomType === ZoomType.xy && d !== this.zoomProgress.plot && d.row === this.zoomProgress.plot.row)) {
        brushArea = new ZoomBrushSelection(
          -1,
          this.zoomProgress.start.y,
          d.absoluteRenderArea.width + 2,
          this.zoomProgress.end.y - this.zoomProgress.start.y);
      }
      if (this.zoomType === ZoomType.xy && d === this.zoomProgress.plot) {
        brushArea = new ZoomBrushSelection(
          this.zoomProgress.start.x,
          this.zoomProgress.start.y,
          this.zoomProgress.end.x - this.zoomProgress.start.x,
          this.zoomProgress.end.y - this.zoomProgress.start.y);
      }
    }
    return brushArea;
  }

  private performChannelCalculations() {
    if (!this.layout) {
      return;
    }

    let xChannel: ProcessedMultiPlotChannel | undefined;
    let visibleXDomains = this.layout.processed.columns[0].processed.channels.filter(v => v.isVisible);
    if (visibleXDomains.length) {
      xChannel = visibleXDomains[0];
    }

    if (!xChannel) {
      return;
    }

    for (let row of this.layout.processed.rows) {
      for (let channel of row.processed.channels) {
        for (let sourceIndex = 0; sourceIndex < channel.sources.length; ++sourceIndex) {
          let integrationChannel = this.layout.processed.sourceData[sourceIndex].featureChannels.integrationChannel;
          if(integrationChannel.name === 'index'
            && (this.calculationType === CalculationType.integral || this.calculationType === CalculationType.gradient)){
            return;
          }
          let xSourceChannel = xChannel.sources[sourceIndex];
          let ySourceChannel = channel.sources[sourceIndex];

          let xExtent = this.layout.columns.length == 1 ?
            d3.extent(this.layout.columns[0].processed.scale.domain()) :
            [-Infinity, Infinity];
          let yExtent = d3.extent(row.processed.scale.domain());

          let yResult = this.getChannelCalculationResult.execute(
            this.calculationType,
            this.domainSnapBehaviour,
            xSourceChannel,
            ySourceChannel,
            integrationChannel,
            [xExtent[0], xExtent[1]],
            [yExtent[0], yExtent[1]]);

          channel.legendValues[sourceIndex] = yResult;
          switch (this.calculationType){
            case CalculationType.gradient:
              channel.unitsOverride = `(${channel.baseUnits})/${integrationChannel.units}`;
              break;
            case CalculationType.integral:
              channel.unitsOverride = `(${channel.baseUnits}).${integrationChannel.units}`;
              break;
          }
        }
      }
    };

    for (let column of this.layout.processed.columns) {
      for (let channel of column.processed.channels) {
        for (let sourceIndex = 0; sourceIndex < channel.sources.length; ++sourceIndex) {
          let result = NaN;
          let data = channel.sources[sourceIndex].data;
          if (data) {
            let xExtent = d3.extentStrict(data);
            result = xExtent[1] - xExtent[0];
          }
          this.getVisibleXLegendChannel(channel).legendValues[sourceIndex] = result;
        }
      }
    }
  }

  private performZoomBrushCalculations(d: ZoomBrushPlot, brushArea: ZoomBrushSelection) {
    if (!this.layout) {
      return;
    }

    if (!d.includeInCalculations || brushArea.width === 0 || brushArea.height === 0) {
      return;
    }

    let xChannel: ProcessedMultiPlotChannel | undefined;
    let visibleXDomains = d.plot.column.processed.channels.filter(v => v.isVisible);
    if (visibleXDomains.length) {
      xChannel = visibleXDomains[0];
    }

    if (!xChannel) {
      return;
    }

    let xScale = d.plot.column.processed.scale;
    let xDomainBounds: [number, number] = [-Infinity, Infinity];

    if (this.zoomType === ZoomType.x || this.zoomType === ZoomType.xy) {
      xDomainBounds = d3.extentStrict([
        xScale.invert(brushArea.x),
        xScale.invert(brushArea.x + brushArea.width)
      ]);
    }

    for (let sourceIndex = 0; sourceIndex < xChannel.sources.length; ++sourceIndex) {

      let integrationChannel = this.layout.processed.sourceData[sourceIndex].featureChannels.integrationChannel;
      if(integrationChannel.name === 'index'
      && (this.calculationType === CalculationType.integral || this.calculationType === CalculationType.gradient)){
        return;
      }
      let xSourceChannel = xChannel.sources[sourceIndex];
      let xData = xSourceChannel.data;

      if (!xData) {
        continue;
      }

      for (let yChannel of d.plot.row.processed.channels) {
        let ySourceChannel = yChannel.sources[sourceIndex];

        if (!ySourceChannel.data) {
          continue;
        }

        let yScale = d.plot.row.processed.scale;
        let yDomainBounds: [number, number] = [-Infinity, Infinity];
        if (this.zoomType === ZoomType.y || (this.zoomType === ZoomType.xy && this.zoomProgress && d.plot === this.zoomProgress.plot)) {
          yDomainBounds = d3.extentStrict([
            yScale.invert(brushArea.y),
            yScale.invert(brushArea.y + brushArea.height)
          ]);
        }

        let yResult = this.getChannelCalculationResult.execute(
          this.calculationType,
          this.domainSnapBehaviour,
          xSourceChannel,
          ySourceChannel,
          integrationChannel,
          xDomainBounds,
          yDomainBounds,
          brushArea.isSelectionReversedX);
        yChannel.legendValues[sourceIndex] = yResult;
        switch (this.calculationType){
          case CalculationType.gradient:
            yChannel.unitsOverride = `(${yChannel.baseUnits})/${integrationChannel.units}`;
            break;
          case CalculationType.integral:
            yChannel.unitsOverride = `(${yChannel.baseUnits}).${integrationChannel.units}`;
            break;
        }
      }

      //Calculate xRanges for current and additional data
      let xDomainIndices = xData.map((v, i) => v >= xDomainBounds[0] && v <= xDomainBounds[1]);
      //Calculating this efficiently depends on monotonicity
      let xRange: number;
      if(xSourceChannel.isMonotonic && this.domainSnapBehaviour === DomainSnapBehaviour.linearInterpolation){
        let xMin = xDomainBounds[0] !== -Infinity ? xDomainBounds[0] : xData.at(0);
        let xMax = xDomainBounds[1] !== Infinity ? xDomainBounds[1] : xData.at(-1);
        xRange = xMax - xMin;
      }else{
        let xValues = xData.filter((v, i) => xDomainIndices[i]);
        let xExtent = d3.extentStrict(xValues);
        xRange = xExtent[1] - xExtent[0];
      }

      if (this.zoomProgress && d.plot === this.zoomProgress.plot && xSourceChannel.isMonotonic) {
        let startIndex = xDomainIndices.indexOf(true);
        let endIndex = xDomainIndices.lastIndexOf(true);
        if (startIndex === -1 || endIndex === -1) {
          continue;
        }
        for (let xAdditionalChannel of d.plot.column.processed.channels) {
          if (xAdditionalChannel === xChannel || !xAdditionalChannel.isMonotonic) {
            continue;
          }

          let xAdditionalData = xAdditionalChannel.sources[sourceIndex].data;
          if (!xAdditionalData) {
            continue;
          }

          let xAdditionalRange: number;
          if(this.domainSnapBehaviour === DomainSnapBehaviour.linearInterpolation && startIndex > 0 && endIndex < xAdditionalData.length - 1){
            let xMin = d3.interpolate(xAdditionalData[startIndex-1], xAdditionalData[startIndex])((xDomainBounds[0] - xData[startIndex - 1])/(xData[startIndex] - xData[startIndex - 1]));
            let xMax = d3.interpolate(xAdditionalData[endIndex], xAdditionalData[endIndex+1])((xDomainBounds[1] - xData[endIndex])/(xData[endIndex + 1] - xData[endIndex]));
            xAdditionalRange = xMax - xMin;
          }else{
            let xAdditionalExtent = d3.extentStrict([xAdditionalData[startIndex], xAdditionalData[endIndex]]);
            xAdditionalRange = xAdditionalExtent[1] - xAdditionalExtent[0];
          }

          this.getVisibleXLegendChannel(xAdditionalChannel).legendValues[sourceIndex] = xAdditionalRange;
        }
      }

      this.getVisibleXLegendChannel(xChannel).legendValues[sourceIndex] = xRange;
    }
  }

  private getVisibleXLegendChannel(channel: ProcessedMultiPlotChannel): ILegendChannel {
    if (!this.xLegendList) {
      throw new Error('X legend list was not found.');
    }

    let xLegendItems = this.xLegendList.channels.filter(v => v.name === channel.name);
    if (!xLegendItems.length) {
      this.xLegendList.addChannel(channel);
      return channel;
    }

    return xLegendItems[0];
  }

  private getScaleRatio() {
    switch (this.zoomType) {
      case ZoomType.x:
        return [1, 0];
      case ZoomType.y:
        return [0, 1];
      default:
        return [1, 1];
    }
  }

  private zoomToZoomProgress(selection: SVGSelection) {
    if (this.isZooming || !this.layout) {
      return;
    }
    try {
      this.isZooming = true;

      if (!this.zoomProgress) {
        return;
      }

      let existingTransform;
      let self = this;
      const zoomProgress = this.zoomProgress;
      //let layout = this.layout;
      selection.selectAll<SVGElement, ProcessedPlot>('.zoom-handler')
        .each(function(plot: ProcessedPlot) {
          if (plot !== zoomProgress.plot) {
            return;
          }

          let brushArea = self.getZoomProgressBrushArea(plot);
          if (!brushArea || brushArea.width === 0 || brushArea.height === 0) {
            return;
          }

          existingTransform = d3xy.xyzoomTransform(this) || d3xy.xyzoomIdentity;

          if (self.zoomType === ZoomType.x || self.zoomType === ZoomType.xy) {
            let scalingFactor = plot.absoluteRenderArea.width / brushArea.width;
            existingTransform = d3xy.xyzoomIdentity
              .translate((existingTransform.x - brushArea.x) * scalingFactor, existingTransform.y)
              .scale(existingTransform.kx * scalingFactor, existingTransform.ky);
          }

          if (self.zoomType === ZoomType.y || self.zoomType === ZoomType.xy) {
            let scalingFactor = plot.absoluteRenderArea.height / brushArea.height;
            existingTransform = d3xy.xyzoomIdentity
              .translate(existingTransform.x, (existingTransform.y - brushArea.y) * scalingFactor)
              .scale(existingTransform.kx, existingTransform.ky * scalingFactor);
          }

          let zoom: any = (this as any)[ZOOM_PROPERTY];
          d3.select(this).call(zoom.transform, existingTransform);
        });

      if (existingTransform) {
        this.applyPlotTransformToNeighbours(selection, zoomProgress.plot, existingTransform);
        this.listeners.call('zoom');
      }
    } finally {
      this.isZooming = false;
    }
  }

  private zoomHandler(selection: SVGSelection, plot: ProcessedPlot, currentEvent: any) {
    if (this.isZooming || !this.layout) {
      return;
    }
    try {
      this.isZooming = true;
      plot = getCurrentPlot(this.layout, plot);

      let transform = currentEvent.transform;
      if (transform.k === Infinity) {
        return;
      }

      this.applyPlotTransformToNeighbours(selection, plot, transform);

      this.listeners.call('zoom');
    } finally {
      this.isZooming = false;
    }
  }

  private applyPlotTransformToNeighbours(selection: SVGSelection, plot: ProcessedPlot, transform: any) {

    let handlers = selection.selectAll<SVGElement, ProcessedPlot>('.zoom-handler');

    handlers
      .each(function(otherPlot: ProcessedPlot) {
        if (!otherPlot || !plot || otherPlot === plot) {
          return;
        }

        if (otherPlot.column === plot.column) {
          let existingTransform = d3xy.xyzoomTransform(this);
          let newTransform = d3xy.xyzoomIdentity.translate(transform.x, existingTransform.y).scale(transform.kx, existingTransform.ky);
          let zoom: any = (this as any)[ZOOM_PROPERTY];
          d3.select(this).call(zoom.transform, newTransform);
        }

        if (otherPlot.row === plot.row) {
          let existingTransform = d3xy.xyzoomTransform(this);
          let newTransform = d3xy.xyzoomIdentity.translate(existingTransform.x, transform.y).scale(existingTransform.kx, transform.ky);
          let zoom: any = (this as any)[ZOOM_PROPERTY];
          d3.select(this).call(zoom.transform, newTransform);
        }
      });
  }

  private getZoomFilter() {
    let self = this;
    return function(currentEvent: any) {

      if (currentEvent.button) { // Ignore clicks other than left click.
        return false;
      }

      if (currentEvent.type === 'dblclick') {
        return false;
      }

      if (self.zoomProgress && currentEvent.type === 'mousedown') {
        return false;
      }

      if (!self.enableZoomByScroll && currentEvent.type === 'wheel') {
        return false;
      }

      return true;
    };
  }

  private renderZoomOptions(selection: SVGSelection, container: d3.Selection<SVGGElement, null, SVGElement, any>) {
    if (!this.settings) {
      return;
    }

    this.toggleOptionsRenderer.renderToggleOptions<ZoomType>(
      selection,
      'zoom',
      OPTIONS_LEFT_PADDING,
      this.settings.svgSize.height - OPTIONS_BOTTOM_PADDING,
      'Zoom',
      30,
      this.zoomToggles,
      this.zoomType,
      () => this.renderZoomOptionsAndBrushes(selection, container));

    this.toggleOptionsRenderer.renderToggleOptions<CalculationType>(
      selection,
      'calculation',
      OPTIONS_LEFT_PADDING + 100,
      this.settings.svgSize.height - OPTIONS_BOTTOM_PADDING,
      'Mode',
      30,
      this.calculationToggles,
      this.calculationType,
      () => this.renderZoomOptionsAndBrushes(selection, container));
  }

  private renderZoomOptionsAndBrushes(selection: SVGSelection, container: d3.Selection<SVGGElement, null, SVGElement, any>) {
    this.renderZoomOptions(selection, container);
    this.renderZoomBrushes(selection, container);
  }
}


class ZoomProgress {
  public end: IPosition;

  constructor(
    public plot: ProcessedPlot,
    public start: IPosition) {
    this.end = start;
  }
}

class ZoomBrushPlot {
  constructor(
    public plot: ProcessedPlot,
    public includeInCalculations: boolean) {
  }
}
