import { SVGSelection } from '../../untyped-selection';
import { IPopulatedMultiPlotLayout } from '../data-pipeline/types/i-populated-multi-plot-layout';
import { ProcessedPlot } from '../data-pipeline/types/processed-plot';
import { IMargin } from '../margin';

interface IChartSettings {
  readonly uniqueId: string;
  readonly spaceBetweenPlots: number;
  readonly chartMargin: IMargin;
}

export class PlotClippingRenderer {
  private inner = new PlotClippingRendererInner();

  public render(selection: SVGSelection): this {
    this.inner.render(selection);
    return this;
  }

  public layout(value: IPopulatedMultiPlotLayout): this {
    this.inner.layout = value;
    return this;
  }

  public chartSettings(value: IChartSettings): this {
    this.inner.settings = value;
    return this;
  }

  public static getPlotClipPathId(uniqueId: string, plotIndex: number) {
    return uniqueId + '-plot-clip-' + plotIndex;
  }

  public static getPlotXAxisClipPathId(uniqueId: string, plotIndex: number) {
    return uniqueId + '-plot-clip-x-' + plotIndex;
  }

  public static getPlotYAxisClipPathId(uniqueId: string, plotIndex: number) {
    return uniqueId + '-plot-clip-y-' + plotIndex;
  }
}

export class PlotClippingRendererInner {
  public layout?: IPopulatedMultiPlotLayout;
  public settings?: IChartSettings;

  public render(selection: SVGSelection) {
    if (!this.layout || !this.settings) {
      return;
    }

    const settings = this.settings;

    let containerClassName = 'multi-plot-clipping-renderer';

    let containerUpdate = selection.selectAll<SVGGElement, any>('.' + containerClassName).data([null]);
    let containerEnter = containerUpdate.enter().append('g')
      .attr('class', containerClassName);
    let container = containerEnter.merge(containerUpdate);

    let gUpdate = container.selectAll<SVGGElement, ProcessedPlot>('.plot-clip-container').data(this.layout.processed.plots as ProcessedPlot[]);
    gUpdate.exit().remove();
    let gEnter = gUpdate.enter().append('g').attr('class', 'plot-clip-container');
    let g = gEnter.merge(gUpdate);

    let plotClipPathUpdate = g.select<SVGClipPathElement>('.plot-clip');
    let plotClipPathEnter = gEnter.append('clipPath')
      .attr('class', 'plot-clip')
      .attr('id', (d, i) => PlotClippingRenderer.getPlotClipPathId(settings.uniqueId, i));
    let plotClipPath = plotClipPathEnter.merge(plotClipPathUpdate);

    let plotXClipPathUpdate = g.select<SVGClipPathElement>('.plot-clip-x');
    let plotXClipPathEnter = gEnter.append('clipPath')
      .attr('class', 'plot-clip-x')
      .attr('id', (d, i) => PlotClippingRenderer.getPlotXAxisClipPathId(settings.uniqueId, i));
    let plotXClipPath = plotXClipPathEnter.merge(plotXClipPathUpdate);

    let plotYClipPathUpdate = g.select<SVGClipPathElement>('.plot-clip-y');
    let plotYClipPathEnter = gEnter.append('clipPath')
      .attr('class', 'plot-clip-y')
      .attr('id', (d, i) => PlotClippingRenderer.getPlotYAxisClipPathId(settings.uniqueId, i));
    let plotYClipPath = plotYClipPathEnter.merge(plotYClipPathUpdate);


    plotClipPathEnter.append('rect');
    plotXClipPathEnter.append('rect');
    plotYClipPathEnter.append('rect');

    plotClipPath.select('rect')
      .attr('x', (d) => d.absoluteRenderArea.x)
      .attr('y', (d) => d.absoluteRenderArea.y)
      .attr('width', (d) => d.absoluteRenderArea.width)
      .attr('height', (d) => d.absoluteRenderArea.height);
    plotXClipPath.select('rect')
      .attr('x', (d) => d.absoluteRenderArea.x - settings.spaceBetweenPlots)
      .attr('y', (d) => d.absoluteRenderArea.y + d.absoluteRenderArea.height)
      .attr('width', (d) => d.absoluteRenderArea.width + 2 * settings.spaceBetweenPlots)
      .attr('height', (d) => settings.spaceBetweenPlots + settings.chartMargin.bottom);
    plotYClipPath.select('rect')
      .attr('x', (d) => d.absoluteRenderArea.x - settings.spaceBetweenPlots - settings.chartMargin.left)
      .attr('y', (d) => d.absoluteRenderArea.y - settings.spaceBetweenPlots)
      .attr('width', (d) => settings.spaceBetweenPlots + settings.chartMargin.left)
      .attr('height', (d) => d.absoluteRenderArea.height + 2 * settings.spaceBetweenPlots);
  }
}
