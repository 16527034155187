export interface IPosition {
  readonly x: number;
  readonly y: number;
}

export class Position {
  constructor(
    private _x: number,
    private _y: number) {
  }

  public get x(): number {
    return this._x;
  }

  public get y(): number {
    return this._y;
  }
}
