import { Vector3 } from 'three';
import { CoordinateTriplet } from './coordinate-triplet';

export class TrackCoordinate {
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly z: number) {
  }

  public get worldX(): number {
    return this.x;
  }

  public get worldY(): number {
    return this.z;
  }

  public get worldZ(): number {
    return -this.y;
  }

  public get worldVector(): Vector3 {
    return new Vector3(this.worldX, this.worldY, this.worldZ);
  }

  public asVector3(): Vector3 {
    return new Vector3(this.x, this.y, this.z);
  }
}

class TrackCoordinateFactoryImplementation {
  public fromArray(coordinate: CoordinateTriplet) {
    return new TrackCoordinate(coordinate[0], coordinate[1], coordinate[2]);
  }

  public fromWorldVector(coordinate: Vector3) {
    return new TrackCoordinate(coordinate.x, -coordinate.z, coordinate.y);
  }
}

export const TrackCoordinateFactory = new TrackCoordinateFactoryImplementation();
