export interface IMinMax {
  readonly minimum: number;
  readonly maximum: number;
}

export class MinMax {
  constructor(
    public readonly minimum: number,
    public readonly maximum: number) {
    if (minimum > maximum) {
      this.minimum = maximum;
      this.maximum = minimum;
    }
  }
}
