import { DomainNews } from './shared-state';

export class DomainNewsCache {
  private _cache: { [name: string]: DomainNews } = {};

  public getDomainNews(name: string): DomainNews {
    let result = this._cache[name];
    if (!result) {
      this._cache[name] = result = new DomainNews();
    }

    return result;
  }
}
