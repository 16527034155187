import { IPopulatedMultiPlotSide } from '../data-pipeline/types/i-populated-multi-plot-side';
import { Utilities } from '../../utilities';
import * as d3 from '../../d3-bundle';
import { definedValues } from '../../defined-values';

export class AxisRendererUtilities {

  public static formatAxis(
    axis: d3.Axis<number | { valueOf(): number }>,
    d: IPopulatedMultiPlotSide,
    meanPixelSpacingBetweenTicks: number,
    numericFormat?: string) {
    let dataLabels = AxisRendererUtilities.getDataLabelsIfSingleChannelWithLabels(d);
    if (dataLabels) {
      const definedDataLabels = dataLabels;
      let domain = d.processed.scale.domain();
      axis.tickValues(definedValues(d3.range(dataLabels.length).map(v => v >= domain[0] && v <= domain[1] ? v : undefined)))
        .tickFormat((v: any, i: number) => i >= domain[0] && i <= domain[1] ? definedDataLabels[i] : '');
    } else {
      let tickCount = d3.max([3, Math.abs(Utilities.diff(d.processed.scale.range())) / meanPixelSpacingBetweenTicks]);
      if (!numericFormat) {
        numericFormat = this.getDefaultNumericFormat(d.processed.scale.domain(), tickCount);
      }
      axis.ticks(tickCount, numericFormat);

    }
  }

  public static getDataLabelsIfSingleChannelWithLabels(side: IPopulatedMultiPlotSide) {
    if (side.processed.channels.length === 1
      && side.processed.channels[0].sources.length
      && side.processed.channels[0].sources[0].dataLabels) {
      return side.processed.channels[0].sources[0].dataLabels;
    }

    return undefined;
  }

  private static getDefaultNumericFormat(domain: number[], tickCount: number): string{
    // Fudge factor of 1.45 to account tick number changing each time. Obtained through testing. Without it the axes often get too precise.
    let tickDomain = Math.abs(Utilities.diff(domain)) * 1.45 / tickCount;
    let format: number;
    if(tickDomain === 0){
      format = 1;
    }else{
      let maxDecimalPlaces = 5;
      let minimalRequiredPrecision = Math.ceil(Math.log10(1 / tickDomain));
      let bigNumberSpacePrecisionLimit = Math.ceil(maxDecimalPlaces - Math.log10(Math.max(Math.abs(domain[0]), Math.abs(domain[1]))));
      let spaceRequirementFormat = Math.min(minimalRequiredPrecision, bigNumberSpacePrecisionLimit, maxDecimalPlaces);
      format =  Math.max(spaceRequirementFormat, 0);
    }
    format = isNaN(format) ? 1 : format;
    return '.' + format + 'f';
  }
}
