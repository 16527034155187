import { WebGLRenderer } from 'three';

export class RendererPoolImplementation {
  private pool: WebGLRenderer[] = [];

  public get(): WebGLRenderer {
    let result = this.pool.pop();
    return result || new WebGLRenderer({ alpha: true, antialias: true, logarithmicDepthBuffer: false });
  }

  public release(renderer: WebGLRenderer) {
    if (renderer) {
      this.pool.push(renderer);
    }
  }
}

export const RendererPool = new RendererPoolImplementation();

