import * as d3 from '../d3-bundle';
import { ISize, Size } from './size';
import { IPosition } from './position';
import { BaseType } from 'd3-selection';

export interface ICanvasSettings {
  readonly svgSize: ISize;
  readonly canvasRetinaSize: ISize;
  readonly pixelRatio: number;
}

export interface ICanvasData {
  readonly parent: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
  readonly settings: ICanvasSettings;
}

export class CanvasUtilities {
  private previousSvgSize: Size | undefined = undefined;

  public getCanvas(canvasData: ICanvasData, className: string): [
    d3.Selection<HTMLCanvasElement, any, BaseType, any>,
    d3.Selection<HTMLCanvasElement, any, BaseType, any>,
    d3.Selection<HTMLCanvasElement, any, BaseType, any>
  ] {
    let canvasUpdate = canvasData.parent.selectAll<HTMLCanvasElement, any>(`.${className}`).data([null]);
    let canvasEnter = canvasUpdate.enter()
      .insert<HTMLCanvasElement>('canvas', 'svg')
      .attr('class', className);
    let canvas = canvasEnter.merge(canvasUpdate);

    let retinaSize = canvasData.settings.canvasRetinaSize;
    let svgSize = canvasData.settings.svgSize;

    if (!this.previousSvgSize || this.previousSvgSize.width !== svgSize.width || this.previousSvgSize.height !== svgSize.height) {
      // Setting the size clears the canvas, so only do it if the size has changed.
      canvas
        .attr('width', retinaSize.width)
        .attr('height', retinaSize.height)
        .style('width', svgSize.width + 'px')
        .style('height', svgSize.height + 'px');
    }

    return [
      canvasUpdate,
      canvasEnter,
      canvas
    ];
  }

  public updateSvgSize(canvasSettings: ICanvasSettings) {
    this.previousSvgSize = new Size(canvasSettings.svgSize.width, canvasSettings.svgSize.height);
  }

  public resetTransform(context: CanvasRenderingContext2D, pixelRatio: number, initialPosition?: IPosition) {
    context.setTransform(1, 0, 0, 1, 0, 0);
    context.scale(pixelRatio, pixelRatio);

    if (initialPosition) {
      context.translate(initialPosition.x, initialPosition.y);
    }
  }
}

