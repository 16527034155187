import { ISize, Size } from './size';
import { IMargin, Margin } from './margin';
import { ILegendSettings, LegendSettings } from './legend-settings';
import * as d3 from '../d3-bundle';
import { createRandomString } from '../create-random-string';

export function getRetinaRatio() {
  let devicePixelRatio = window.devicePixelRatio || 1;
  let c = <any>(<any>document.createElement('canvas')).getContext('2d');
  let backingStoreRatio = [
    c.webkitBackingStorePixelRatio,
    c.mozBackingStorePixelRatio,
    c.msBackingStorePixelRatio,
    c.oBackingStorePixelRatio,
    c.backingStorePixelRatio,
    1
  ].reduce(function(a, b) {
    return a || b;
  });

  return devicePixelRatio / backingStoreRatio;
}

export type GetChannelColorDelegate = (channelIndex: number, sourceIndex: number) => string;

export class ChartSettings {

  private _uniqueId: string = createRandomString(10);
  private _sourceCount!: number;
  private _legend: ILegendSettings;
  private _svgSize!: ISize;
  private _svgPadding: IMargin;
  private _chartMargin: IMargin;
  private _chartSize!: ISize;
  private _getChannelColor!: GetChannelColorDelegate;
  private _pixelRatio: number;
  private _canvasRetinaSize!: ISize;

  constructor(sourceCount: number) {
    this.sourceCount = sourceCount;

    this._legend = new LegendSettings({
      blobSize: 10,
      blobSpacing: 2,
      valueWidth: 55,
      unitsWidth: 52,
      maximumLabelWidth: 150,
      renderChannelCount: 0,
      renderChannelWidth: 0
    });

    this._pixelRatio = getRetinaRatio();

    this._svgPadding = new Margin(0, 0, 0, 0);
    this._chartMargin = new Margin(0, 0, 0, 0);

    this.svgSize = new Size(1024, 768); // This is important if the viewer is first rendered hidden, as this size is used.
  }

  public get uniqueId(): string {
    return this._uniqueId;
  }

  public get sourceCount(): number {
    return this._sourceCount;
  }
  public set sourceCount(value: number) {
    this._sourceCount = value;

    switch (value) {
      case 1: {
        let coloursOrdinal = d3.scaleOrdinal<number, string>(d3.schemeCategory10).domain(d3.range(0, 10));
        this._getChannelColor = (channelIndex, sourceIndex) => coloursOrdinal(channelIndex % 10);
        break;
      }
      case 2: {
        let coloursOrdinal = d3.scaleOrdinal<number, string>([
          '#1F77B4', '#AEC7E8',
          '#FF7F0E', '#FFBB78',
          '#2CA02C', '#98DF8A',
          '#D62728', '#FF9896',
          '#9467BD', '#C5B0D5',
          '#8C564B', '#C49C94',
          '#E377C2', '#F7B6D2',
          '#7F7F7F', '#C7C7C7',
          '#BCBD22', '#DBDB8D',
          '#17BECF', '#9EDAE5'
        ]).domain(d3.range(0, 20));
        this._getChannelColor = (channelIndex, sourceIndex) => coloursOrdinal((2 * channelIndex + sourceIndex) % 10);
        break;
      }
      default: {
        let coloursOrdinal = d3.scaleOrdinal<number, string>(d3.schemeCategory10).domain(d3.range(0, 10));
        this._getChannelColor = (channelIndex, sourceIndex) => coloursOrdinal(sourceIndex % 10);
      }
    }
  }

  public get legend(): ILegendSettings {
    return this._legend;
  }

  public get svgSize(): ISize {
    return this._svgSize;
  }
  public set svgSize(value: ISize) {
    this._svgSize = value;
    this.updateChartSize();
    this.updateCanvasRetinaSize();
  }

  public get svgPadding(): IMargin {
    return this._svgPadding;
  }
  public set svgPadding(value: IMargin) {
    this._svgPadding = value;
    this.updateChartSize();
  }

  public get chartMargin(): IMargin {
    return this._chartMargin;
  }
  public set chartMargin(value: IMargin) {
    this._chartMargin = value;
    this.updateChartSize();
  }

  public get chartSize(): ISize {
    return this._chartSize;
  }

  public get getChannelColor(): GetChannelColorDelegate {
    return this._getChannelColor;
  }

  public get pixelRatio(): number {
    return this._pixelRatio;
  }

  public get canvasRetinaSize(): ISize {
    return this._canvasRetinaSize;
  }

  private updateChartSize() {
    this._chartSize = new Size(
      this._svgSize.width
      - this._svgPadding.left - this._svgPadding.right
      - this._chartMargin.left - this._chartMargin.right,
      this._svgSize.height
      - this._svgPadding.top - this._svgPadding.bottom
      - this._chartMargin.top - this._chartMargin.bottom);
  }

  private updateCanvasRetinaSize() {
    this._canvasRetinaSize = ChartSettings.getCanvasRetinaSize(this.svgSize, this.pixelRatio);
  }

  public static getCanvasRetinaSize(svgSize: ISize, pixelRatio: number) {
    return new Size(svgSize.width * pixelRatio, svgSize.height * pixelRatio);
  }

  public static build(sourceCount: number): ChartSettings {
    return new ChartSettings(sourceCount);
  }
}
